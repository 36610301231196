import { FlexColumn, FlexRow, GridColumn, Text } from 'components/box';
import React, { useState } from 'react';
import { MenuItemWrapper, SideNavbarWrapper } from './styled';
import Image from 'components/Image';
import { RiCoinFill, RiPulseFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineSetting, AiOutlineLogout } from 'react-icons/ai';
import { FiGrid, FiSearch } from 'react-icons/fi';
import { IoCubeOutline, IoHardwareChipOutline } from 'react-icons/io5';
import { FaRegComment } from 'react-icons/fa';
import UserBadge from 'components/UserBadge';
import { useSideBar } from 'state/layout/hooks';
import useResponsive from 'hooks/useResponsive';
import useConfirm from 'hooks/useConfirm';
import { useAppDispatch } from 'state';
import { LOGOUT } from 'state/profile/action';
import { useProfile } from 'state/profile/hooks';
import useApi from 'hooks/useApi';
import usePopup from 'hooks/usePopup';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import SetttingConstant from 'components/SettingConstant';

function MenuItem({
  logo,
  path,
  name,
  onClick,
}: {
  logo?: React.ReactNode;
  path?: string;
  name?: string;
  onClick?: (any) => any;
}) {
  const sideBar = useSideBar();
  const { pathname } = useLocation();
  let active = false;
  if (name === 'dashboard') {
    if (pathname === '/dashboard' || pathname === '/admin/dashboard')
      active = true;
  } else {
    if (pathname.includes(path)) active = true;
  }

  if (path)
    return (
      <Link to={path}>
        <MenuItemWrapper sideBar={sideBar} active={active}>
          {logo}
          <Text size={'lg'} className="text">
            {name}
          </Text>
          {!sideBar && <div className="tooltip">{name}</div>}
        </MenuItemWrapper>
      </Link>
    );

  return (
    <MenuItemWrapper onClick={onClick} sideBar={sideBar} active={active}>
      {logo}
      <Text size={'lg'} className="text">
        {name}
      </Text>
      {!sideBar && <div className="tooltip">{name}</div>}
    </MenuItemWrapper>
  );
}

function SideNavbar({ admin }: { admin?: boolean }) {
  const sideBar = useSideBar();
  const isMobile = useResponsive('mobileL');
  const { isConfirmed } = useConfirm();
  const dispatch = useAppDispatch();
  const profile = useProfile();
  const [setting, setSetting] = useState(false);

  const onClickLogout = async () => {
    const con = await isConfirmed({
      title: 'Log Out',
      text: 'Are you sure you want to logout?',
    });
    if (con.confirm) {
      dispatch(LOGOUT());
    }
  };

  const api = useApi();
  const popUp = usePopup();

  const menuList = !admin
    ? [
        {
          name: 'Dashboard',
          logo: <RiPulseFill />,
          path: '/dashboard',
        },
        // {
        //   name: 'User',
        //   logo: <FiGrid />,
        //   path: '/dashboard/user',
        // },
        {
          name: 'Register',
          logo: <IoCubeOutline />,
          path: '/register',
        },
        // {
        //   name: 'Hub',
        //   logo: <IoHardwareChipOutline />,
        //   path: '/dashboard/hub',
        // },
        // {
        //   name: 'Contact',
        //   logo: <FaRegComment />,
        //   path: '/dashboard/contact',
        // },
      ]
    : [
        {
          name: 'Dashboard',
          logo: <RiPulseFill />,
          path: '/admin/dashboard/user',
        },
        {
          name: 'Reward Approval',
          logo: <RiCoinFill />,
          path: '/admin/dashboard/reward',
        },
      ];

  return (
    <SideNavbarWrapper sideBar={sideBar}>
      {/* Contant */}
      <ModalPopup
        show={setting}
        onClose={() => setSetting(false)}
        title="Setting Constant"
      >
        <SetttingConstant onClose={() => setSetting(false)} />
      </ModalPopup>
      <GridColumn className="menu_contain" gap="1rem">
        {!isMobile && (
          <FlexRow className="menu_alight">
            <Image
              className="logo"
              src="/image/logo/logo.svg"
              alt="climate-positive-logo"
            />
            <Text size={'xl'} className="text_main">
              Climate Positive +
            </Text>
          </FlexRow>
        )}
        {menuList.map((e) => (
          <MenuItem {...e} key={e.name} />
        ))}
      </GridColumn>
      <GridColumn className="menu_foot" gap="1rem">
        {profile.isAdmin ? (
          <MenuItem
            logo={<AiOutlineSetting />}
            name={'Setting CC'}
            onClick={() => {
              setSetting(true);
            }}
          />
        ) : null}
        <MenuItem
          logo={<AiOutlineLogout />}
          name={'Logout'}
          onClick={onClickLogout}
          key={'logout'}
        />
        <FlexRow className="menu_alight">
          <UserBadge
            style={{ height: '4rem', width: '4rem', fontSize: '3rem' }}
          />
          <Text size={'xl'} className="text_main">
            {profile.islogin && profile.data.username}
          </Text>
        </FlexRow>
      </GridColumn>
    </SideNavbarWrapper>
  );
}

export default SideNavbar;
