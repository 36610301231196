import Endpoint from '../Endpoint';

export default class AuthEndpoited extends Endpoint {
  async login(data) {
    return await this.axiosWrapper
      .post(`/login`, data)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async requestPassword(data) {
    return await this.axiosWrapper
      .post(`/requestpassword`, data)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async resetPassword(token, data) {
    return await this.axiosWrapper
      .post(`/resetpassword`, data, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
