import axios from 'axios';
import AxiosWrapper from './AxiosWrapper';
import PublicService from './PublicService';
import ServerService from './ServerService';
import jwt from 'jsonwebtoken';

export const api = (token) => {
  token = window.localStorage.getItem('TOKEN');

  if (token) {
    const decoded: any = jwt.decode(token);

    // console.log(decoded.exp, Date.now())

    if (decoded.exp * 1000 < Date.now()) {
      token = undefined;
      window.localStorage.removeItem('TOKEN');
      // window.location.href = '/login';
    }
  }

  return axios.create({
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
    },
  });
}


export const apiPub = axios.create({
  headers: {},
});

export const apiServer = (token) =>
  new ServerService(
    new AxiosWrapper(api(token), process.env.REACT_APP_API_HOST)
  );

export const apiPublic = new PublicService(
  new AxiosWrapper(apiPub, process.env.REACT_APP_API_HOST)
);
