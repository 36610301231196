import { DatePicker, Form, Select } from 'antd';
import LayoutContent from 'components/Layout/LayoutContent';
import React, { useEffect, useState } from 'react';
import { UserDetailsWrapper } from './styled';
import { GridColumn, Text } from 'components/box';
import Input from 'components/Input';
import Selectoption from 'components/Selectoption';
import useCountry from 'hooks/useCountry';
import DatePickerWrapper from 'components/Input/DatePickerWrapper';
import { AiFillCalendar } from 'react-icons/ai';
import ImagePicker from 'components/ImagePicker';
import { isAddress } from 'utils';
import Button from 'components/Button';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import useApi from 'hooks/useApi';
import moment from 'moment';

interface Props {
  edit?: boolean;
}

const { Option } = Select;

const initialState: dataForm = {
  role: '',
  name: '',
  email: '',
  country: '',
  username: '',
  installationDate: null,
  installedCapacity: '',
  type: '',
  period: '',
  picture: [],
  location: '',
  // wallet
  walletAddress: '',

  siteId: '',
  projectCode: '',
  apiKey: '',
};

function UserDetails({ edit }: Props) {
  const { id } = useParams();
  console.log(id);
  const [data, setData] = useState<dataForm>(initialState);
  const [rawData, setRawData] = useState<any>({});
  const [form] = Form.useForm<dataForm>();
  const country = useCountry();
  const type = ['Self ownership', 'Corporate ownership', 'Financial ownership'];
  const navigate = useNavigate();
  const [loadingsub, setLoadingSub] = useState(false);
  const { isConfirmed } = useConfirm();
  const popUp = usePopup();
  const apiServer = useApi();
  const [loading, setLoading] = useState(true);

  const [images, setImages] = useState([]);

  const format = 'YYYY/MM/DD';

  const onFinish = async (e: dataForm) => {
    setLoadingSub(true);
    const confirm = await isConfirmed({
      text: `Comfirm edit to ${data.username}`,
    });
    if (confirm.confirm) {
      const result = await apiServer.kyc.submitKycByMod({
        ...rawData,
        ...e,
        picture: images,
      });
      if (result.success) {
        popUp.success({ text: `edit to ${data.username} success!` });
      } else {
        popUp.error({ text: `edit to ${data.username} failed!` });
      }
    }
    setLoadingSub(false);
  };

  const fetchData = async () => {
    const result = await apiServer.device.getBySiteId(id);
    console.log(result);
    // "2022-02-09T09:07:13.022Z"
    if (result.success && result.data) {
      const date = result.data.kycData.installationDate;
      const dateParse = moment(date);
      setRawData(result.data);
      setData({ ...result.data.kycData, installationDate: dateParse });
      const pic =
        result.data.kycData.picture.map((e, i) => {
          return {
            uid: i.toString(),
            name: 'image' + i.toString(),
            status: 'done',
            url: e,
          };
        }) || [];
      setImages(pic);
      setLoading(false);
    } else {
      navigate('/admin/dashboard/user');
    }
  };

  const onValuesChange = (e) => {};

  useEffect(() => {
    fetchData();
  }, []);

  console.log(images);

  const onFinishFailed = (e) => {};
  return (
    <LayoutContent title="User Details">
      <UserDetailsWrapper>
        {!loading && (
          <Form
            initialValues={data}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            autoComplete="off"
            form={form}
            layout={'vertical'}
            className="form_container"
          >
            <Form.Item
              className="form_item"
              label={<Text>Name / Corporate Name (Full Name)</Text>}
              name="name"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Please input your Name!',
                },
              ]}
            >
              <Input disabled={!edit} size={'middle'} type={'text'} />
            </Form.Item>
            <Form.Item
              className="form_item"
              label={<Text> Email address</Text>}
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                disabled={!edit}
                autoComplete="off"
                size={'middle'}
                type={'email'}
              />
            </Form.Item>

            <Selectoption>
              <Form.Item
                className="form_item"
                label={<Text> Country of Residence</Text>}
                name="country"
                rules={[
                  {
                    required: true,
                    message: 'Please input your country!',
                  },
                ]}
              >
                <Select
                  disabled={!edit}
                  style={{ width: '100%' }}
                  showSearch={true}
                  placeholder="Select Country of residence"
                >
                  {country.map((e, i) => (
                    <Option key={'country-' + e.name} value={JSON.stringify(e)}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Selectoption>

            <Form.Item
              className="form_item"
              label={<Text>Username</Text>}
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input disabled={!edit} size={'middle'} type={'text'} />
            </Form.Item>

            {/* type2 */}

            {data.role !== 'tokenholder' && (
              <Form.Item
                className="form_item"
                label={<Text>Project Code</Text>}
                name="projectCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input your project code!',
                  },
                ]}
              >
                <Input disabled={!edit} size={'middle'} placeholder="STP000" />
              </Form.Item>
            )}

            {data.role !== 'tokenholder' && (
              <Form.Item
                className="form_item"
                label={<Text>Site ID</Text>}
                name="siteId"
                rules={[
                  {
                    required: true,
                    message: 'Please input your site ID!',
                  },
                ]}
              >
                <Input disabled={!edit} size={'middle'} />
              </Form.Item>
            )}

            {data.role !== 'tokenholder' && (
              <Form.Item
                className="form_item"
                label={<Text>API Key</Text>}
                name="apiKey"
                rules={[
                  {
                    required: true,
                    message: 'Please input your API Key!',
                  },
                ]}
              >
                <Input disabled={!edit} size={'middle'} />
              </Form.Item>
            )}

            {data.role !== 'tokenholder' && (
              <DatePickerWrapper>
                <Form.Item
                  className="form_item"
                  label={<Text>Operation Started (Month, Year)</Text>}
                  name="installationDate"
                  rules={[
                    {
                      required: true,
                      type: 'date',
                      message: 'Please input your Start date!',
                    },
                  ]}
                >
                  <DatePicker
                    format={format}
                    disabled={!edit}
                    // defaultValue={moment(data.installationDate)}
                    suffixIcon={<AiFillCalendar />}
                    style={{ width: '100%' }}
                    picker="month"
                  />
                </Form.Item>
              </DatePickerWrapper>
            )}

            {data.role !== 'tokenholder' && (
              <Form.Item
                className="form_item"
                label={<Text>Installed Capacity</Text>}
                name="installedCapacity"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Installed capacity!',
                  },
                ]}
              >
                <Input
                  disabled={!edit}
                  size={'middle'}
                  type={'number'}
                  suffix={'kW'}
                />
              </Form.Item>
            )}

            {data.role !== 'tokenholder' && (
              <Selectoption>
                <Form.Item
                  className="form_item"
                  label={<Text>Type of Ownership</Text>}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your type of ownership!',
                    },
                  ]}
                >
                  <Select
                    disabled={!edit}
                    style={{ width: '100%' }}
                    showSearch={false}
                    placeholder="Select Type of ownership"
                    optionFilterProp="children"
                    dropdownClassName="dropdown"
                    // bordered={false}
                  >
                    {type.map((e, i) => (
                      <Option key={`type-${i}`} value={e}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Selectoption>
            )}

            {data.role !== 'tokenholder' && (
              <Form.Item
                className="form_item"
                label={<Text>Remaining ownership period</Text>}
                name="period"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Period!',
                  },
                ]}
              >
                <Input
                  disabled={!edit}
                  size={'middle'}
                  type={'number'}
                  suffix={'Year'}
                />
              </Form.Item>
            )}

            {data.role !== 'tokenholder' && (
              <GridColumn gap="0.5rem">
                <Text>System overall picture</Text>
                <ImagePicker
                  fileList={images}
                  disabled={!edit}
                  onChange={(x) => {
                    if (edit) {
                      setImages(x);
                    }
                  }}
                ></ImagePicker>
              </GridColumn>
            )}

            <Form.Item
              className="form_item"
              label={<Text>Wallet Address (BSC)</Text>}
              name="walletAddress"
              rules={[
                {
                  validator: async (_, walletAddress) => {
                    if (!isAddress(walletAddress)) {
                      return Promise.reject(
                        new Error('Please input your Wallet address!')
                      );
                    }
                  },
                },
              ]}
            >
              <Input disabled={!edit} size={'middle'} type={'text'} />
            </Form.Item>
            {edit && (
              <Form.Item className="form_button">
                <Button
                  loading={loadingsub}
                  type={'submit'}
                  style={{ width: '100%', marginTop: '1rem' }}
                  text="Next"
                />
              </Form.Item>
            )}
          </Form>
        )}
      </UserDetailsWrapper>
    </LayoutContent>
  );
}

export default UserDetails;
