import colors from 'colors/colors';
import styled from 'styled-components/macro';
import { device } from 'styling/fast';

// fix

export const LayoutWrapper = styled.div<{ sideBar: boolean }>`
  /* padding-bottom: 2rem; */
  position: relative;
  .content {
    width: 100%;
    padding-left: ${({ sideBar }) => (sideBar ? '25rem' : '7rem')};
    position: relative;
    min-height: 100vh;
    transition: 0.5s all ease-in-out;
    @media ${device.mobileL} {
      padding-left: 0;
      padding-top: 10rem;
    }
  }
  .auth_content {
    width: 100%;
    min-height: 100vh;
    width: 100%;
    /* padding: 4rem; */
  }
  .button_toggle_nav {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
`;

export const LayoutContentWrapper = styled.div<{
  noTopBg?: Boolean;
  width?: string;
}>`
  min-height: 100vh;
  width: ${({ width }) => (width ? width : '100%')};
  margin: 0 auto;
  position: relative;
  padding-bottom: 2rem;
`;

export const SideNavbarWrapper = styled.div<{ sideBar: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: ${({ sideBar }) => (sideBar ? '25rem' : '7rem')};
  height: 100vh;
  background-color: ${({ theme }) => theme.nav};
  padding: 2rem 0;
  transition: 0.5s all ease-in-out;
  overflow-x: hidden;
  @media ${device.mobileL} {
    height: calc(100vh - 7rem);
    top: 7rem;
  }
  .logo {
    width: 4rem;
    height: 4rem;
  }
  .menu_contain {
    height: 70%;
  }
  .menu_foot {
    height: 30%;
  }
  .menu_alight {
    width: 100%;
    padding-left: 1.5rem;
  }
  .text_main {
    width: 25rem;
    margin-left: 2rem;
    color: ${({ theme }) => theme.main};
    opacity: ${({ sideBar }) => (sideBar ? '1' : '0')};
    transition: 0.4s all ease-in-out;
  }
  @media ${device.mobileL} {
    width: ${({ sideBar }) => (sideBar ? '25rem' : '0')};
  }
`;

export const MenuItemWrapper = styled.div<{
  active: boolean;
  sideBar: boolean;
}>`
  width: 100%;
  position: relative;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme, active }) => (active ? theme.main : theme.menu)};
  font-size: 2rem;
  padding-left: 2.5rem;
  cursor: pointer;
  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    color: ${colors.gray[9]};
    font-size: 1.5rem;
    position: absolute;
    left: 100%;
    padding: 0.2rem 1rem;
    background-color: ${({ theme }) => theme.gray_4};
  }
  :hover {
    color: ${({ theme }) => theme.main};
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
    :before {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  :before {
    transition: all 0.5s ease-in-out;
    opacity: ${({ active }) => (active ? '1' : '0')};
    content: '';
    position: absolute;
    width: 0.4rem;
    height: 80%;
    background-color: ${({ theme }) => theme.main};
    left: 0;
    transform: ${({ active }) => (active ? 'scaleY(1)' : 'scaleY(0)')};
  }
  .text {
    margin-left: 2rem;
    color: ${({ theme, active }) => (active ? theme.main : theme.menu)};
    opacity: ${({ sideBar }) => (sideBar ? '1' : '0')};
    transition: 0.4s all ease-in-out;
  }
`;

export const TopNavbarWrapper = styled.div`
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  @media ${device.mobileL} {
    margin-bottom: 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 2rem;
    z-index: 5;
    background-color: ${({ theme }) => theme.minimal};
  }
  .logo {
    height: 3rem;
    width: 3rem;
  }
`;
