import {
  FlexColumn,
  FlexRow,
  GridColumn,
  GridRow,
  Text,
  TextB,
} from 'components/box';
import UserBadge from 'components/UserBadge';
import React from 'react';
import { UserItemWrapper } from './styled';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Badge from 'components/Badge';
import DropdownStyled from 'components/Dropdown';
import { colorBadge } from 'interface';
import { Menu } from 'antd';
import {
  AiFillCheckCircle,
  AiOutlineEye,
  AiFillEdit,
  AiFillDelete,
  AiOutlineFileExcel,
} from 'react-icons/ai';
import usePopup from 'hooks/usePopup';
import useConfirm from 'hooks/useConfirm';
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function UserItem({
  color,
  data,
  setLoading,
}: {
  color?: colorBadge;
  data: any;
  setLoading: () => any;
}) {
  const colorBadge = {
    systemowner: 'purple',
    tokenholder: 'orange',
    both: 'blue',
  };

  const colorStatus = {
    pending: 'purple',
    approved: 'green',
    rejected: 'red',
  };

  let countryFind = {
    flags: {
      svg: '',
    },
  };

  const { isConfirmed } = useConfirm();
  const popup = usePopup();
  const apiServer = useApi();

  const deleteUser = async (item) => {
    const confirm = await isConfirmed({ text: `Delete ${item.username}` });
    if (confirm) {
      const result = await apiServer.device.delete({ siteId: item.siteId });
      if (result.success) {
        popup.success({ text: `Delete ${item.username} success!` });
      } else {
        popup.error({ text: `Delete ${item.username} failed!` });
      }
      setLoading();
    }
  };

  const approveUser = async (item) => {
    const confirm = await isConfirmed({ text: `Approve ${item.username}` });
    if (confirm) {
      const result = await apiServer.device.approve(true, item.siteId);
      if (result.success) {
        popup.success({ text: `Approve ${item.username} success!` });
      } else {
        popup.error({ text: `Approve ${item.username} failed!` });
      }
      setLoading();
    }
  };

  const rejectUser = async (item) => {
    const confirm = await isConfirmed({
      text: `Reject Reason:`,
      input: true,
      type: 'text',
    });
    if (confirm && confirm.value) {
      const result = await apiServer.device.rejected(
        false,
        item.siteId,
        confirm.value
      );
      if (result.success) {
        popup.success({ text: `${item.username} was successfully rejected!` });
      } else {
        popup.error({ text: `Reject ${item.username} failed!` });
      }
      setLoading();
    }
  };

  let country = null;

  if (data.kycData) {
    country = JSON.parse(data.kycData.country);
  }

  const roleParse = {
    systemowner: 'SYSTEM OWNER',
    tokenholder: 'TOKEN HOLDER',
    both: 'BOTH',
  };

  const menu = (
    <Menu>
      {data.kycStatus !== 'approved' && (
        <Menu.Item key="approve" onClick={() => approveUser(data)}>
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiFillCheckCircle style={{ marginRight: '1rem' }} /> Approve
            </FlexRow>
          </Text>
        </Menu.Item>
      )}

      {data.kycStatus !== 'rejected' && (
        <Menu.Item key="approve" onClick={() => rejectUser(data)}>
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiOutlineFileExcel style={{ marginRight: '1rem' }} /> Rejecte
            </FlexRow>
          </Text>
        </Menu.Item>
      )}
      <Link to={`/admin/dashboard/user/${data.siteId}`}>
        <Menu.Item key="see">
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiOutlineEye style={{ marginRight: '1rem' }} /> See details
            </FlexRow>
          </Text>
        </Menu.Item>
      </Link>
      <Link to={`/admin/dashboard/user/edit/${data.siteId}`}>
        <Menu.Item key="edit">
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiFillEdit style={{ marginRight: '1rem' }} /> Edit
            </FlexRow>
          </Text>
        </Menu.Item>
      </Link>
      <Menu.Item key="delete" onClick={() => deleteUser(data)}>
        <Text>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <AiFillDelete style={{ marginRight: '1rem' }} /> Delete
          </FlexRow>
        </Text>
      </Menu.Item>
    </Menu>
  );

  console.log(data);
  return (
    <UserItemWrapper>
      <FlexRow className="container">
        <FlexRow>
          <UserBadge
            className="user_img"
            src={data.kycData.profilePicture[0]}
            country={data.kycData.country}
          />
          <GridColumn gap="0.8rem">
            <TextB size="base">
              {data.username} ({data.email})
            </TextB>
            <Text size="md" weight="300">
              <HiOutlineLocationMarker style={{ marginRight: '0.2rem' }} />
              {country.name}
            </Text>
          </GridColumn>
        </FlexRow>

        <GridColumn className="action_contain">
          {/* <GridRow className="detail">
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
          </GridRow> */}
          <FlexRow className="menu_action">
            {data.kycStatus !== 'approved' && (
              <FlexColumn
                onClick={() => approveUser(data)}
                className="menu_action_items"
              >
                <AiFillCheckCircle className="icon" />
                <Text>Approve</Text>
              </FlexColumn>
            )}

            {data.kycStatus !== 'rejected' && (
              <FlexColumn
                onClick={() => rejectUser(data)}
                className="menu_action_items"
              >
                <AiOutlineFileExcel className="icon" />
                <Text>Reject</Text>
              </FlexColumn>
            )}
            <Link to={`/admin/dashboard/user/${data.siteId}`}>
              <FlexColumn className="menu_action_items">
                <AiOutlineEye className="icon" />
                <Text>Info</Text>
              </FlexColumn>
            </Link>

            <Link to={`/admin/dashboard/user/edit/${data.siteId}`}>
              <FlexColumn className="menu_action_items">
                <AiFillEdit className="icon" />
                <Text>Edit</Text>
              </FlexColumn>
            </Link>

            <FlexColumn
              onClick={() => deleteUser(data)}
              className="menu_action_items"
            >
              <AiFillDelete className="icon" />
              <Text>Delete</Text>
            </FlexColumn>
          </FlexRow>
          <FlexRow className="action_last">
            <Badge color={colorBadge[data.role]} text={roleParse[data.role]} />
            <div className="vertical_line" style={{ margin: '0.5rem' }}></div>
            <Badge
              color={colorStatus[data.kycStatus]}
              text={data.kycStatus.toUpperCase()}
            />
          </FlexRow>
        </GridColumn>
      </FlexRow>
      {/* <FlexRow className="dropdown_btn">
       
        <DropdownStyled menu={menu} />
      </FlexRow> */}
    </UserItemWrapper>
  );
}
export default UserItem;
