import { AppState, useAppSelector } from 'state';
import { ProfileStae } from 'state/types';

export const useToken = () => {
  const token = useAppSelector((state: AppState) => state.profile.token);
  if (!window.localStorage.getItem('TOKEN')) return '';
  return token;
};

export const useAccount = () => {
  const account = useAppSelector((state) => state.profile.account);
  return account;
};

export const useProfile = () => {
  const account: ProfileStae = useAppSelector((state) => state.profile);
  return account;
};

export const useIsLogin = () => {
  const IsLogin = useAppSelector((state) => state.profile.islogin);
  return IsLogin;
};
