import ToggleThemeBtn from 'components/ToggleTheme';
import { DEFAULT_META, getCustomMeta } from 'config/meta';
import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { Outlet, useLocation } from 'react-router-dom';
import { useSideBar } from 'state/layout/hooks';
import { LayoutWrapper } from './styled';

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { pathname } = useLocation();
  const pageMeta = getCustomMeta(pathname);
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

function AuthLayout() {
  const sideBar = useSideBar();
  return (
    <LayoutWrapper sideBar={sideBar}>
      <PageMeta />
      <div className="button_toggle_nav">
        <ToggleThemeBtn />
      </div>
      <div className="auth_content">
        <Outlet />
      </div>
    </LayoutWrapper>
  );
}

export default AuthLayout;
