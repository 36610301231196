import { GridColumn, TextH2, Text, FlexColumn, FlexRow } from 'components/box';
import Button from 'components/Button';
import Image from 'components/Image';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Step2Wrapper } from './styled';

function KycStep5(props: PropsStep) {
  const theme = useTheme();
  return (
    <Step2Wrapper style={{ margin: '4rem 0' }}>
      <GridColumn gap="1rem">
        <FlexColumn>
          <TextH2 style={{ margin: '1rem 0' }} align="center">
            Congrats! You're all set.
          </TextH2>
          <Text
            size="base"
            align="center"
            style={{ width: '40rem', margin: '0 auto' }}
          >
            Our team are working on your application. Feel free to watch our
            introductory video and we’ll send a confirmation email once you are
            part of the Climate Positive+ Team!
          </Text>
        </FlexColumn>
        <GridColumn gap="1rem">
          <FlexRow>
            <Image width={'20rem'} src="/image/kyc/complete.svg" />
          </FlexRow>
        </GridColumn>
        <Button
          disabled={false}
          style={{
            width: '20rem',
            fontSize: '1.4rem',
            padding: '1rem 0',
            borderRadius: '2rem',
            margin: '0 auto',
          }}
          color={theme.graph1_2}
          text="Waiting for approval"
        />
        <Link to="/">
          <FlexRow>
            <Button
              tone="blue"
              disabled={false}
              style={{
                width: '20rem',
                fontSize: '1.4rem',
                padding: '1rem 0',
                borderRadius: '2rem',
              }}
              text="Back"
            />
          </FlexRow>
        </Link>
      </GridColumn>
    </Step2Wrapper>
  );
}

export default KycStep5;
