import { FlexRow, GridColumn, GridRow, Text, TextB } from 'components/box';
import Button from 'components/Button';
import UserBadge from 'components/UserBadge';
import TokenDepositer from 'contracts/TokenDepositer';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useAuth from 'hooks/useAuth';
import usePopup from 'hooks/usePopup';
import web3 from 'hooks/web3';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import addressParse from 'utils/addressParse';
import { callApi } from 'utils/api';
import { getWalletAddress } from 'utils/token';

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

function ClaimReward({ siteId, pendingWithdraw, pendingWithdraw2, refreshData }) {
  const { active, activate, account: accountweb3 } = useActiveWeb3React();
  const theme = useTheme();

  const { login, logout } = useAuth();

  const [reward, setReward] = useState(null);
  const [reward2, setReward2] = useState(null);
  const [rewardRaw, setRewardRaw] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pendingApprovedWithdraw, setPendingApprovedWithdraw] = useState(null);
  const popup = usePopup();

  async function unlockWallet() {
    try {
      setLoading(true);

      await login(ConnectorNames.Injected);
    } finally {
      setLoading(false);
    }
  }

  async function refreshApprovedWithdraws() {
    const data = await callApi({
      action: 'getPendingWithdraw',
      args: [],
    })

    console.log(data)

    setPendingApprovedWithdraw(data.find(x => x.signature.startsWith('1_withdraw_' + siteId)) ?? null);
    setLoading(false);
  }

  async function claimReward() {
    try {
      setLoading(true);

      const account = await getWalletAddress();
      if (accountweb3.toLowerCase() != account.toLowerCase()) {
        throw new Error("Please switch metamask wallet to " + addressParse(account, 4));
      }

      await callApi({
        action: 'claimReward',
        args: {
          siteId,
        }
      });

      await wait(15000);

      refreshData();

      popup.success({
        text: 'Claim reward success. Please wait for admin approval.',
      })
    } catch (err) {
      console.error(err);
      popup.error({
        text: 'Claim reward failed: ' + (err.response?.data?.message ?? err.message ?? "Unknown error")
      })
    } finally {
      setLoading(false);
    }
  }

  async function withdrawReward() {
    try {
      setLoading(true);

      const account = await getWalletAddress();
      if (accountweb3.toLowerCase() != account.toLowerCase()) {
        throw new Error("Please switch metamask wallet to " + addressParse(account, 4));
      }

      let signature = pendingApprovedWithdraw.signature;
      let [_a, _b, _c, adminAddress, r, s, v] = signature.split('_');
      let tx = await new TokenDepositer(account, account).executeMetaTransaction(adminAddress, pendingApprovedWithdraw.note, pendingApprovedWithdraw.nonce, r, s, v);

      await wait

      refreshData();
      refreshApprovedWithdraws();

      popup.success({
        text: 'Withdraw reward success.',
      })
    } catch (err) {
      console.error(err);
      popup.error({
        text: 'Withdraw reward failed: ' + (err.response?.data?.message ?? err.message ?? "Unknown error")
      })
    } finally {
      setLoading(false);
    }
  }

  async function refreshReward() {
    const data = await callApi({
      action: 'getReward',
      args: {
        siteId
      },
    })

    setRewardRaw(data);

    setReward(data.reward[0]);
    setReward2(data.reward[0]);

    // if (data.IERCMode) {
    //   setReward(data.reward[1]);
    // } else {
    //   setReward(data.reward[0]);
    // }

    console.log(data)
  }

  useEffect(() => {
    refreshApprovedWithdraws()
  }, [pendingWithdraw]);

  useEffect(() => {
    refreshReward();
  }, [])

  return (
    <GridColumn className="box" gap="1rem">
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexRow>
          <UserBadge
            style={{
              width: '4rem',
              height: '4rem',
              marginRight: '1rem',
              padding: '0.1rem',
              fontSize: '2rem',
            }}
          />
          <GridColumn gap="0.2rem">
            <TextB>Climate Positive+</TextB>
            <Text>( DIM )</Text>
          </GridColumn>
        </FlexRow>
        <BiDotsVerticalRounded size={30} color={theme.textp} />
      </FlexRow>
      <Text style={{ lineHeight: '1.5' }} weight="300" size="base">
        Climate Positive represents a net contributor who preserves the
        environment and global climate via its environmentally conscious
        activity. With the implementation of platform and DiM token on
        blockchain embedded technology, the simple yet effective crowd energy
        saving scheme was used to accelerate public awareness and value
        creation. Thus, allowing anyone to easily contribute and shifting
        towards Greener Society.
      </Text>
      {pendingApprovedWithdraw ?
        <GridRow className="btn_contain" gap="1rem">
          <Button
            style={{ fontSize: '1.4rem', flex: '1 1 100%' }}
            text={accountweb3 ? "Withdraw Reward (" + parseFloat(web3.utils.fromWei(pendingApprovedWithdraw.amount)).toFixed(2) + ")" : "Connect Wallet"}
            onClick={accountweb3 ? withdrawReward : unlockWallet}
            disabled={loading}
          />
        </GridRow>
        :
        <>
          <GridRow className="btn_contain" gap="1rem">
            <Button
              style={{ fontSize: '1.4rem', flex: '1 1 100%' }}
              text={pendingWithdraw > 0 ? "Pending approval" : (accountweb3 ? "Claim Reward" : "Connect Wallet")}
              onClick={accountweb3 ? claimReward : unlockWallet}
              disabled={pendingWithdraw > 0 || loading}
            />
          </GridRow>

          <GridRow className="btn_contain" gap="1rem">
            <div style={{ flex: '1 1 45%', flexDirection: 'column', alignItems: 'center' }}>
              <Text align='center' style={{ marginBottom: 4 }}>CC</Text>

              <Button
                style={{ fontSize: '1.4rem', width: '100%' }}
                text={pendingWithdraw > 0 ? parseFloat(web3.utils.fromWei(pendingWithdraw)).toFixed(2) : reward !== null ? parseFloat(web3.utils.fromWei(reward)).toFixed(2) : "Loading..."}
                disabled={true}
              />
            </div>

            <div style={{ flex: '1 1 45%', flexDirection: 'column', alignItems: 'center' }}>
              <Text align='center' style={{ marginBottom: 4 }}>IREC</Text>

              <Button
                style={{ fontSize: '1.4rem', width: '100%' }}
                text={pendingWithdraw2 > 0 ? parseFloat(web3.utils.fromWei(pendingWithdraw2)).toFixed(2) : reward2 !== null ? parseFloat(web3.utils.fromWei(reward2)).toFixed(2) : "Loading..."}
                disabled={true}
              />
            </div>

          </GridRow>
        </>
      }
    </GridColumn>
  );
}

export default ClaimReward;
