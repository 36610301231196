import { FlexRow } from 'components/box';
import { SimpleComponent } from 'interface';
import Image from 'components/Image';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AppState } from 'state';

const UserBadgeWrapper = styled(FlexRow)`
  position: relative;
  .country {
    position: absolute;
    width: 3rem;
    height: 3rem;
    /* padding: 5%; */
    border-radius: 100%;
    background: ${({ theme }) => theme.box};
    bottom: -10%;
    right: -10%;
    img {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .user_img_inside {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

interface Props extends SimpleComponent {
  src?: string;
  country?: string;
  countryAlt?: string;
}

function UserBadge(props: Props) {
  const theme = useTheme();
  const profilePicture = useSelector(
    (state: AppState) => state.profile.profilePicture
  );

  let country = null;

  if (props.country) {
    country = JSON.parse(props.country);
  }

  let src = props.src || profilePicture;

  return (
    <UserBadgeWrapper
      style={{
        borderRadius: '100%',
        padding: '0.5rem',
        background: theme.gray_2,
        ...props.style,
      }}
      className={props.className}
    >
      {src ? (
        <Image
          style={{ borderRadius: '100%', width: '100%', height: '100%' }}
          src={src}
          alt="user"
          className="user_img_inside"
        />
      ) : (
        <FaUserCircle color={theme.main} />
      )}
      {props.country && (
        <FlexRow className="country">
          <Image
            style={{ borderRadius: '100%' }}
            src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
            alt={country.name}
          />
        </FlexRow>
      )}
    </UserBadgeWrapper>
  );
}

export default UserBadge;
