import { Signature, splitSignature } from '@ethersproject/bytes'
import web3 from "../hooks/web3"
import TokenManager from "./TokenDepositer"

export interface SignatureWithFunctionSignature extends Signature {
  functionSignature: string;
  nonce: number;
}

export async function generateSignature(tokenAddress: string, functionSignature: string, ContractClass): Promise<SignatureWithFunctionSignature> {
  const account = (await web3.eth.getAccounts())[0];

  // console.log('Token Address', tokenAddress);
  // console.log('Account', account);
  // console.log('Function Signature', functionSignature);

  // try to gather a signature for permission
  const token = await new ContractClass(tokenAddress, account);
  const nonce = Date.now();
  // console.log(nonce)
  const name = await token.name();
  // console.log(name)
  const chainId = await web3.eth.getChainId();

  const EIP712Domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ]

  const domain = {
    name: name,
    version: '1',
    chainId,
    verifyingContract: tokenAddress,
  }

  const MetaTransaction = [
    { name: 'nonce', type: 'uint256' },
    { name: 'from', type: 'address' },
    { name: 'functionSignature', type: 'bytes' },
  ]
  const message = {
    nonce,
    from: account,
    functionSignature
  }
  const typedData = {
    types: {
      EIP712Domain,
      MetaTransaction,
    },
    domain,
    primaryType: 'MetaTransaction',
    message,
  }

  console.log({
    method: "eth_signTypedData_v4",
    params: [account, JSON.stringify(typedData)],
    from: account
  })

  // Get a signable message from the typed data
  // const signingKey = new utils.SigningKey(privateKey);
  // const typedMessage = getMessage(typedData, true);
  // let signature = signingKey.signDigest(typedMessage);
  const signatureBytes: any = await new Promise((resolve, reject) => {
    (web3.currentProvider as any).sendAsync({
      method: "eth_signTypedData_v4",
      params: [account, JSON.stringify(typedData)],
      from: account
    }, function (err, result) {
      if (err) {
        reject(err);
        return;
      }

      return resolve(result.result);
    });
  });
  // console.log(signatureBytes);

  const signature = splitSignature(signatureBytes);
  // console.log(signature);

  return {
    ...signature,
    functionSignature: functionSignature,
    nonce,
  }
}

// WITHDRAW SIGNATURE

export async function generateERC20WithdrawSignature(tokenAddress, orderId, to, amount): Promise<SignatureWithFunctionSignature> {
  const functionSignature = web3.eth.abi.encodeFunctionCall(
    {
      "inputs": [
        { "internalType": "uint256", "name": "invoiceId", "type": "uint256" },
        {
          "internalType": "contract IMintableERC20",
          "name": "token",
          "type": "address"
        },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "amount", "type": "uint256" }
      ],
      "name": "withdrawERC20",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    [orderId, tokenAddress, to, amount],
  );

  return await generateSignature(process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT, functionSignature, TokenManager);
}