import { GridColumn, GridRow, Text, TextB } from 'components/box';
import LayoutContent from 'components/Layout/LayoutContent';
import Loading from 'components/Layout/Loading';
import useApi from 'hooks/useApi';
import usePopup from 'hooks/usePopup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state';
import { useSideBar } from 'state/layout/hooks';
import { SETSITEID } from 'state/profile/action';
import { numberWithCommas } from 'utils/changeNumberofData';
import { getWalletAddress } from 'utils/token';
import ClaimReward from './ClaimReward';
import MainSelect from './MainSelect';
import PopularState from './PopularState';
import PowerConsumation from './PowerConsumation';
import SolarGraph from './SolarGraph';
import SolorEstimated from './SolorEstimated';
import { DashBoardWrapper } from './styled';

function formatDate(date, mode) {
  let parts = date.split(' ')[0].split('-');
  let parts2 = date.split(' ')[1].split(':');

  if (mode == 'month') {
    return parts[2] + '/' + parts[1];
  } else if (mode == 'day') {
    return parts2[0] + ':' + parts2[1];
  } else {
    return parts[1] + '/' + parts[0];
  }
}

function Home() {
  const siteId = useSelector((state: AppState) => state.profile.selectedSiteId);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [chart, setChart] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [totalSystemSize, setTotalSystemSize] = useState(0);
  const sideBar = useSideBar();
  const popup = usePopup();
  const apiServer = useApi();
  const dispatch = useDispatch();
  const [select, setSelect] = useState('day');
  const type = ['day', 'month', 'year'];

  async function loadDevices() {
    let walletAddress = await getWalletAddress();

    try {
      let devicesResponse = await apiServer.device.getByWallet(walletAddress);
      let devicesRaw = devicesResponse.data.devices;
      devicesRaw = devicesRaw.filter((x) => x.role != 'tokenholder');
      // console.log(devicesRaw)
      setDevices(devicesRaw);

      if (devicesRaw.length > 0 && !siteId) {
        dispatch(SETSITEID(devicesRaw[0].siteId));
      }
    } catch (err) {
      console.error(err);
      popup.error({
        text: 'Cannot get devices. please logout and login again.',
      });
    }
  }

  async function loadChart() {
    try {
      let timeUnit = 'MONTH';
      let startTime;

      if (select == 'month') {
        timeUnit = 'DAY';
        startTime = new Date();
        startTime.setMonth(startTime.getMonth() - 1);
      } else if (select == 'day') {
        timeUnit = 'HOUR';
        startTime = new Date();
        startTime.setHours(startTime.getHours() - 24);
      } else if (select == 'year') {
        timeUnit = 'MONTH';
        startTime = new Date();
        startTime.setFullYear(startTime.getFullYear() - 1);
      }

      let chartResponse = await apiServer.device.getChart(
        siteId,
        timeUnit,
        startTime, 
        new Date()
      );
      let productionChart = [];
      for (let i = 0; i < chartResponse.data.production.length; i++) {
        const x = chartResponse.data.production[i];
        const production = Math.ceil(
          // (productionChart[productionChart.length - 1]?.production || 0) +
            x.value / 1000
        );
        const cc =
          // (productionChart[productionChart.length - 1]?.cc || 0) +
          (x.value / 1000000) * chartResponse.data.MUL_CONST.MWh_CC;
        productionChart.push({
          name: formatDate(x.date, select),
          production,
          cc,
        });

        productionChart[productionChart.length - 1].cc = parseFloat(
          parseFloat(productionChart[productionChart.length - 1].cc).toFixed(2)
        );
      }

      // Apply bonus to chart
      // let bonus =
      //   (chartResponse.data.totalProduction -
      //     chartResponse.data.production.reduce((acc, x) => acc + x.value, 0)) /
      //   1000;
      // for (let i = 0; i < productionChart.length; i++) {
      //   productionChart[i].production += bonus;
      //   productionChart[i].cc += bonus / 1000 * chartResponse.data.MUL_CONST.MWh_CC;

      //   productionChart[i].production = Math.ceil(
      //     productionChart[i].production
      //   );
      //   productionChart[i].cc = Math.ceil(productionChart[i].cc);
      // }

      console.log(productionChart);
      setChart(chartResponse.data);
      setChartData(productionChart);

      setLoading(false);
    } catch (err) {
      console.error(err);
      popup.error({ text: 'Cannot get chart data. please logout and login again.' });
    }
  }

  async function refreshLeaderboard() {
    let response = await apiServer.device.getLeaderboard();
    if (response.data) {
      setLeaderboard(response.data.data);
      setTotalSystemSize(response.data.totalSystemSize);
    }
  }

  useEffect(() => {
    refreshLeaderboard();
  }, []);

  useEffect(() => {
    loadDevices();
  }, []);

  useEffect(() => {
    if (siteId) {
      setLoading(true);
      loadChart();
    }
  }, [siteId, select]);

  if (loading) {
    return <Loading></Loading>;
  }

  const hoursInDuration = select == 'day' ? 24 : select == 'month' ? 720 : 8760;
  const localSolarGenerated = chart.production.reduce(
    (acc, x) => acc + x.value,
    0
  );
  const targetEfficiency =
    parseFloat(chart.device.systemSize) * 1000 * hoursInDuration * 0.17;

  return (
    <LayoutContent title="Dashboard">
      <DashBoardWrapper sidebar={sideBar}>
        <GridColumn gap="1rem">
          <MainSelect
            devices={devices}
            select={select}
            setSelect={setSelect}
            type={type}
            capacity={numberWithCommas(
              parseFloat(chart.device.systemSize).toFixed(2)
            )}
            location={chart.device.kycData?.siteDetail?.location || {}}
          />
          <div className="main_container">
            <GridColumn gap="1rem">
              <GridRow min="15rem">
                <GridColumn className="box" gap="1rem">
                  <Text>Electric Generated To Date</Text>
                  <TextB weight="500">
                    {numberWithCommas(Math.ceil(chart.totalProduction / 1000))}{' '}
                    kWh
                  </TextB>
                </GridColumn>
                <GridColumn className="box" gap="1rem">
                  <Text>Carbon Reduction To Date</Text>
                  <TextB weight="500">
                    {numberWithCommas(
                      Math.ceil(
                        (chart.totalProduction / 1000000) * chart.MUL_CONST.MWh_CC
                      )
                    )}{' '}
                    Tons
                  </TextB>
                </GridColumn>
                <GridColumn className="box" gap="1rem">
                  <Text>Revenue Generated To Date</Text>
                  <TextB weight="500">
                    {numberWithCommas(
                      (
                        (chart.totalProduction / 1000000) *
                        chart.MUL_CONST.MWh_CC *
                        chart.MUL_CONST.CC_USD
                      ).toFixed(2)
                    )}{' '}
                    $
                  </TextB>
                </GridColumn>
              </GridRow>
              <SolarGraph data={chartData} />
              <GridRow className="pie_conainer" gap="2rem">
                <SolorEstimated
                  current={localSolarGenerated / 1000000}
                  total={targetEfficiency / 1000000}
                />
                <PowerConsumation
                  current={parseFloat(chart.device.systemSize)}
                  total={totalSystemSize}
                />
              </GridRow>
            </GridColumn>
            <GridColumn gap="1rem">
              <ClaimReward
                siteId={siteId}
                // pendingWithdraw={chart.device ? (chart.MUL_CONST.IRECMode ? chart.device.pendingWithdraw2 : chart.device.pendingWithdraw) : 0}
                pendingWithdraw={chart.device ? chart.device.pendingWithdraw : 0}
                pendingWithdraw2={chart.device ? chart.device.pendingWithdraw2 : 0}
                refreshData={() => {
                  setLoading(true);
                  loadChart();
                }}
              />
              <PopularState
                MWh_CC={chart.MUL_CONST.MWh_CC}
                leaderboard={leaderboard}
              />
            </GridColumn>
          </div>
        </GridColumn>
      </DashBoardWrapper>
    </LayoutContent>
  );
}

export default Home;
