import AxiosWrapper from './AxiosWrapper';
import AuthEndpoited from './model/public/auth';
import DevicesEndpointed from './model/server/devices';
import KycEndpointed from './model/server/kyc';
import RewardEndpointed from './model/server/reward';

export default class ServerService {
  public axiosWrapper: AxiosWrapper;

  readonly kyc: KycEndpointed;
  readonly device: DevicesEndpointed;
  readonly reward: RewardEndpointed;

  constructor(axiosWrapper: AxiosWrapper) {
    this.kyc = new KycEndpointed(axiosWrapper);
    this.device = new DevicesEndpointed(axiosWrapper);
    this.reward = new RewardEndpointed(axiosWrapper);
  }
}
