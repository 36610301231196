import styled, { useTheme } from 'styled-components';
import {
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Legend,
  ComposedChart,
  Bar,
  Line,
  Label,
} from 'recharts';
import { Text } from 'components/box';
import { device } from 'styling/fast';

const SolarGraphWrapper = styled.div`
  width: 100%;
  height: 30rem;
  @media ${device.tablet} {
    height: 40rem;
  }
`;

function SolarGraph({ data }) {
  const theme = useTheme();

  console.log(data);

  return (
    <SolarGraphWrapper className="box">
      <Text>Solar Generated (kWh), Carbon Reduction</Text>
      <ResponsiveContainer width={'100%'} height={'80%'}>
        <ComposedChart
          data={data}
          margin={{ top: 10, right: 30, left: 10, bottom: 20 }}
        >
          <XAxis tickMargin={10} dataKey="name">
            {/* <Label
              value="Pages of my website"
              offset={-15}
              position="insideBottom"
              fill={theme.text}
            /> */}
          </XAxis>
          <YAxis
            tickMargin={10}
            yAxisId={'left'}
            orientation={'left'}
            domain={[(dataMin) => Math.floor(dataMin * 0.75), 'dataMax']}
            label={{
              value: 'kWh',
              position: 'top',
              textAnchor: 'middle',
              offset: 14,
              fill: theme.text,
            }}
          ></YAxis>
          <YAxis
            tickMargin={10}
            yAxisId={'right'}
            orientation={'right'}
            domain={[(dataMin) => Math.floor(dataMin * 0.75), 'dataMax']}
            label={{
              value: 'Tons',
              position: 'top',
              textAnchor: 'middle',
              offset: 14,
              fill: theme.text,
            }}
          />
          <Legend verticalAlign="top" height={36} />
          <Tooltip />
          <Line
            yAxisId={'left'}
            type="monotone"
            dataKey="production"
            name="Production"
            unit=" kWh"
            fillOpacity={1}
            stroke={theme.graph1_1}
            fill={theme.graph1_2}
            strokeWidth={3}
          />
          <Bar
            barSize={10}
            yAxisId={'right'}
            type="monotone"
            dataKey="cc"
            name="Carbon Reduction"
            unit=" Tons"
            fillOpacity={1}
            stroke={theme.graph2_1}
            fill={theme.graph2_2}
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </SolarGraphWrapper>
  );
}

export default SolarGraph;

const data = [
  {
    name: 'Page A',
    expected: 4000,
    Real: 2400,
  },
  {
    name: 'Page B',
    expected: 3000,
    Real: 1398,
  },
  {
    name: 'Page C',
    expected: 9800,
    Real: 2000,
  },
  {
    name: 'Page D',
    expected: 4000,
    Real: 3000,
  },
  {
    name: 'Page E',
    expected: 5000,
    Real: 3000,
  },
  {
    name: 'Page F',
    expected: 4444,
    Real: 3333,
  },
  {
    name: 'Page G',
    expected: 9000,
    Real: 8000,
  },
];
