import { FlexColumn, FlexRow, TextH2, Text } from 'components/box';
import { KycTypeWrapper, Step1Wrapper } from './styled';
import Image from 'components/Image';
import Button from 'components/Button';

function KycType({
  role,
  text,
  des,
  setCurrent,
  success,
  setSuccess,
  setData,
  active,
}) {
  function onClick() {
    setData((prev) => {
      return { ...prev, role };
    });
    if (success === 0) {
      setSuccess(1);
    }
    setCurrent(2);
  }

  return (
    <KycTypeWrapper active={active}>
      <Image className="img_role" src={`/image/kyc/${role}.jpg`} alt="role" />
      <TextH2 size={'xl'}>{text}</TextH2>
      <Text style={{ textAlign: 'center', marginTop: '1rem' }}>{des}</Text>
      <Button
        onClick={onClick}
        className="btn"
        text="Continue"
        disabled={false}
      />
    </KycTypeWrapper>
  );
}

function KycStep1(props: PropsStep) {
  return (
    <Step1Wrapper>
      <FlexColumn className="container">
        <TextH2 align="center">Choose Your Role</TextH2>
        <FlexRow className="type_container">
          <KycType
            success={props.success}
            active={props.data.role === 'tokenholder'}
            setSuccess={props.setSuccess}
            setCurrent={props.setCurrent}
            setData={props.setData}
            role="tokenholder"
            text={'Token Holder'}
            des={
              'I want to participate in Climate Positive initiative by acquiring DiM Token.'
            }
          />
          <KycType
            success={props.success}
            active={props.data.role === 'systemowner'}
            setSuccess={props.setSuccess}
            setCurrent={props.setCurrent}
            setData={props.setData}
            role="systemowner"
            text={'System Owner'}
            des={'I have Solar System ready to connect to digiMint Platform.'}
          />
          <KycType
            success={props.success}
            setData={props.setData}
            active={props.data.role === 'both'}
            setSuccess={props.setSuccess}
            setCurrent={props.setCurrent}
            role="both"
            text={'Both'}
            des={'I have Solar System ready to connect to digiMint Platform.'}
          />
        </FlexRow>
      </FlexColumn>
    </Step1Wrapper>
  );
}

export default KycStep1;
