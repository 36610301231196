import AxiosWrapper from './AxiosWrapper';
import AuthEndpoited from './model/public/auth';

export default class PublicService {
  private readonly axiosWrapper: AxiosWrapper;

  readonly auth: AuthEndpoited;

  constructor(axiosWrapper: AxiosWrapper) {
    this.auth = new AuthEndpoited(axiosWrapper);
  }
}
