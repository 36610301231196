import {
  FlexColumn,
  FlexRow,
  GridColumn,
  GridRow,
  Text,
  TextB,
} from 'components/box';
import UserBadge from 'components/UserBadge';
import React from 'react';
import { UserItemWrapper } from './styled';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Badge from 'components/Badge';
import DropdownStyled from 'components/Dropdown';
import { colorBadge } from 'interface';
import { Menu } from 'antd';
import {
  AiFillCheckCircle,
  AiOutlineEye,
  AiFillEdit,
  AiFillDelete,
  AiOutlineFileExcel,
} from 'react-icons/ai';
import usePopup from 'hooks/usePopup';
import useConfirm from 'hooks/useConfirm';
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';
import web3 from 'hooks/web3';
import { callApi } from 'utils/api';
import { generateERC20WithdrawSignature } from 'contracts/generateSignature';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useAuth from 'hooks/useAuth';
import addressParse from 'utils/addressParse';
import { getWalletAddress } from 'utils/token';

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

function UserItem({
  color,
  data,
  setLoading,
}: {
  color?: colorBadge;
  data: any;
  setLoading: () => any;
}) {
  const { active, account: accountweb3 } = useActiveWeb3React();
  const { login, logout } = useAuth();

  const colorBadge = {
    systemowner: 'purple',
    tokenholder: 'orange',
    both: 'blue',
  };

  const colorStatus = {
    pending: 'purple',
    approved: 'green',
    rejected: 'red',
  };

  let countryFind = {
    flags: {
      svg: '',
    },
  };

  const { isConfirmed } = useConfirm();
  const popup = usePopup();
  const apiServer = useApi();

  async function unlockWallet() {
    await login(ConnectorNames.Injected);
    popup.error({ text: `Please approve again!` });
  }

  const deleteUser = async (item) => {
    const confirm = await isConfirmed({ text: `Delete ${item.username}` });
    if (confirm) {
      const result = await apiServer.device.delete({ siteId: item.siteId });
      if (result.success) {
        popup.success({ text: `Delete ${item.username} success!` });
      } else {
        popup.error({ text: `Delete ${item.username} failed!` });
      }
      setLoading();
    }
  };

  const approveUser = async (item) => {
    if (!accountweb3) {
      unlockWallet();
      return;
    }

    const account = await getWalletAddress();
    if (accountweb3.toLowerCase() != account.toLowerCase()) {
      popup.error({ text: "Please switch metamask wallet to " + addressParse(account, 4) });
      return;
    }

    const confirm = await isConfirmed({ text: `Approve reward for user ${item.username} site ${item.siteId}` });
    if (confirm) {
      const orderId = Date.now().toString() + Math.floor(Math.random() * 1000).toString();
      await callApi({
        action: 'txWithdraw',
        args: {
          adminSignature: await generateERC20WithdrawSignature(process.env.REACT_APP_DIM_TOKEN_ADDRESS, orderId, item.walletAddress, item.pendingWithdraw),
          siteId: item.siteId,
          orderId,
          walletAddress: item.walletAddress,
          adminAddress: account,
        },
        async then(data) {
          popup.success({ text: `Approve success!` });
        },
        async error(data) {
          popup.error({ text: `Approve failed!` });
        }
      })
      // const result = await apiServer.device.approve(true, item.siteId);
      // if (result.success) {
      //   popup.success({ text: `Approve success!` });
      // } else {
      //   popup.error({ text: `Approve failed!` });
      // }
      setLoading();
    }
  };

  const rejectUser = async (item) => {
    const confirm = await isConfirmed({
      text: `Confirm reject reward for user ${item.username} site ${item.siteId}`,
    });
    if (confirm) {
      const result = await apiServer.reward.rejectPendingWithdraw(
        item
      );
      if (result.success) {
        popup.success({ text: `Successfully rejected!` });
      } else {
        popup.error({ text: `Reject failed!` });
      }
      setLoading();
    }
  };

  let country = null;

  if (data.kycData) {
    country = JSON.parse(data.kycData.country);
  }

  const roleParse = {
    systemowner: 'SYSTEM OWNER',
    tokenholder: 'TOKEN HOLDER',
    both: 'BOTH',
  };

  const menu = (
    <Menu>
      {data.kycStatus !== 'approved' && (
        <Menu.Item key="approve" onClick={() => approveUser(data)}>
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiFillCheckCircle style={{ marginRight: '1rem' }} /> Approve
            </FlexRow>
          </Text>
        </Menu.Item>
      )}

      {data.kycStatus !== 'rejected' && (
        <Menu.Item key="approve" onClick={() => rejectUser(data)}>
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiOutlineFileExcel style={{ marginRight: '1rem' }} /> Rejecte
            </FlexRow>
          </Text>
        </Menu.Item>
      )}
      <Link to={`/admin/dashboard/user/${data.siteId}`}>
        <Menu.Item key="see">
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiOutlineEye style={{ marginRight: '1rem' }} /> See details
            </FlexRow>
          </Text>
        </Menu.Item>
      </Link>
      <Link to={`/admin/dashboard/user/edit/${data.siteId}`}>
        <Menu.Item key="edit">
          <Text>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <AiFillEdit style={{ marginRight: '1rem' }} /> Edit
            </FlexRow>
          </Text>
        </Menu.Item>
      </Link>
      <Menu.Item key="delete" onClick={() => deleteUser(data)}>
        <Text>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <AiFillDelete style={{ marginRight: '1rem' }} /> Delete
          </FlexRow>
        </Text>
      </Menu.Item>
    </Menu>
  );

  console.log(data);
  return (
    <UserItemWrapper>
      <FlexRow className="container">
        <FlexRow>
          <UserBadge
            className="user_img"
            src={data.kycData.profilePicture[0]}
            country={data.kycData.country}
          />
          <GridColumn gap="0.8rem">
            <TextB size="base">
              {data.username} ({data.email})
            </TextB>
            <Text size="md" weight="300">
              <HiOutlineLocationMarker style={{ marginRight: '0.2rem' }} />
              {country.name}
            </Text>
          </GridColumn>
        </FlexRow>

        <GridColumn className="action_contain" style={{ gridTemplateColumns: "1fr 33rem" }}>
          {/* <GridRow className="detail">
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
            <GridColumn className="detail_item" gap="1rem">
              <TextB size="xl">24</TextB>
              <Text size="md">PROJECTS</Text>
            </GridColumn>
          </GridRow> */}
          <FlexRow className="menu_action">
            <FlexColumn
              onClick={() => approveUser(data)}
              className="menu_action_items"
            >
              <AiFillCheckCircle className="icon" />
              <Text>Approve</Text>
            </FlexColumn>

            <FlexColumn
              onClick={() => rejectUser(data)}
              className="menu_action_items"
            >
              <AiOutlineFileExcel className="icon" />
              <Text>Reject</Text>
            </FlexColumn>

            <Link to={`/admin/dashboard/user/${data.siteId}`}>
              <FlexColumn className="menu_action_items">
                <AiOutlineEye className="icon" />
                <Text>Info</Text>
              </FlexColumn>
            </Link>

            {/* <Link to={`/admin/dashboard/user/edit/${data.siteId}`}>
              <FlexColumn className="menu_action_items">
                <AiFillEdit className="icon" />
                <Text>Edit</Text>
              </FlexColumn>
            </Link>

            <FlexColumn
              onClick={() => deleteUser(data)}
              className="menu_action_items"
            >
              <AiFillDelete className="icon" />
              <Text>Delete</Text>
            </FlexColumn> */}
          </FlexRow>
          <FlexRow className="action_last">
            <Badge color={colorBadge[data.role]} text={data.role != 'tokenholder' ? 'Site ' + data.siteId : roleParse[data.role]} />
            <div className="vertical_line" style={{ margin: '0.5rem' }}></div>
            <Badge
              color={'purple'}
              text={web3.utils.fromWei(data.pendingWithdraw) + ' DiM'}
              style={{ marginRight: '0.5rem' }}
            />
            <Badge
              color={'purple'}
              text={web3.utils.fromWei(data.pendingWithdrawAlternative) + ' DiM'}
              style={{ opacity: 0.5 }}
            />
          </FlexRow>
        </GridColumn>
      </FlexRow>
      {/* <FlexRow className="dropdown_btn">
       
        <DropdownStyled menu={menu} />
      </FlexRow> */}
    </UserItemWrapper>
  );
}
export default UserItem;
