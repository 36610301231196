import { DEFAULT_META, getCustomMeta } from 'config/meta';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSideBar } from 'state/layout/hooks';
import { useProfile } from 'state/profile/hooks';
import SideNavbar from '../SideNavbar';
import { LayoutWrapper } from '../styled';

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { pathname } = useLocation();
  const pageMeta = getCustomMeta(pathname);
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

function AdminLayout() {
  const sideBar = useSideBar();
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isAdmin) {
      navigate('/admin/login');
    }
  }, [profile]);
  return (
    <LayoutWrapper sideBar={sideBar}>
      <PageMeta />
      <div className="content">
        <Outlet />
      </div>
      <SideNavbar admin={true} />
    </LayoutWrapper>
  );
}

export default AdminLayout;
