import { SimpleComponent } from 'interface';
import React, { ReactChild } from 'react';
import styled from 'styled-components';

interface Props extends SimpleComponent {
  children: ReactChild;
}

const InputWrapper = styled.div`
  .ant-picker {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    outline: 0;
    border-color: ${({ theme }) => theme.gray_2};
    background-color: ${({ theme }) => theme.box};
    color: ${({ theme }) => theme.gray_8};
  }

  input[disabled] {
    color: ${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_2};
  }
  .ant-picker.ant-picker-disabled {
    color: ${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_2};
  }
  input {
    background-color: transparent;
    font-size: 1.4rem;
  }
  .ant-picker-suffix {
    color: ${({ theme }) => theme.main};
    font-size: 2rem;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }
  .ant-picker:hover,
  .ant-picker-hover {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-picker-clear {
  }
`;

function DatePickerWrapper(props: Props) {
  return (
    <InputWrapper className={props.className} style={props.style}>
      {props.children}
    </InputWrapper>
  );
}

export default DatePickerWrapper;
