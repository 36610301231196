import styled from 'styled-components';
import { boxBg, device } from 'styling/fast';

export const DashBoardWrapper = styled.div<{ sidebar?: boolean }>`
  .main_container {
    width: 100%;
    max-width: 100%s;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr;
    margin: 1rem 0;
    gap: 2rem;
  }
  .pie_conainer {
    grid-template-columns: 1fr 1fr;
    height: 25rem;
  }
  .box {
    ${boxBg}
  }

  @media ${device.tablet} {
    .main_container {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    .pie_conainer {
      height: auto;
      grid-template-columns: 1fr;
      grid-template-rows: 30rem 30rem;
    }
  }
  .btn_contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;
