import { Pagination } from 'antd';
import React from 'react';
import styled from 'styled-components';

const PaginationStyledWrapper = styled.div`
  .ant-pagination {
    * {
      color: white;
    }
  }
  .ant-pagination-item {
    padding-top: 0.5rem;
    background-color: ${({ theme }) => theme.graph2_1};
    border: 0;
    border-radius: 100%;
    :hover {
      background-color: ${({ theme }) => theme.main};
      border: 0;
      a {
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.main};
  }
  .ant-pagination-item-link {
    background-color: ${({ theme }) => theme.orange};
    border: 0;
    border-radius: 100%;
  }
`;

function PaginationStyled({ page, onChange, total, row }) {
  console.log(page);

  if ((page = 1 && total <= row)) return null;

  return (
    <PaginationStyledWrapper>
      <Pagination
        // current={page}
        pageSize={row}
        onChange={onChange}
        total={total}
      />
    </PaginationStyledWrapper>
  );
}

export default PaginationStyled;
