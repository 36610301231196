import { FlexRow, TextH1 } from 'components/box';
import React from 'react';
import { KycWrapper, LoadingPercent } from './styled';
import Image from 'components/Image';
import ToggleThemeBtn from 'components/ToggleTheme';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { Link } from 'react-router-dom';

const transalate = {
  '1': 'Step 1 Your Role',
  '2': 'Step 2 Basic Information',
  '3': 'Step 3 System Information',
  '4': 'Wallet Information',
  '5': 'Finished',
};

function LayoutKyc(props: PropsKycHead) {
  const goPrevios = () => {
    if (props.current === 4 && props.data.role === 'tokenholder') {
      props.setCurrent(2);
    } else {
      props.setCurrent(props.current - 1);
    }
  };

  const goNext = () => {
    if (props.current <= props.success) {
      if (props.current === 2 && props.data.role === 'tokenholder') {
        props.setCurrent(4);
      } else {
        props.setCurrent(props.current + 1);
      }
    }
  };

  return (
    <KycWrapper>
      <FlexRow className="nav">
        <FlexRow>
          <Link to={'/'}>
            <FlexRow className="logo_contain">
              <Image src="/image/logo/logo.svg" alt="climate positive logo" />
            </FlexRow>
          </Link>
          {props.current !== 1 && props.current !== 5 && (
            <FlexRow onClick={goPrevios} className="control">
              <IoIosArrowDropleftCircle />
            </FlexRow>
          )}

          <TextH1 size={'xl'}>{transalate[props.current]}</TextH1>
          {props.current !== 5 && props.current <= props.success && (
            <FlexRow onClick={goNext} className="control">
              <IoIosArrowDroprightCircle />
            </FlexRow>
          )}
        </FlexRow>

        <ToggleThemeBtn />
      </FlexRow>
      <LoadingPercent percent={props.percent}>
        <div />
      </LoadingPercent>
      {props.children}
    </KycWrapper>
  );
}

export default LayoutKyc;
