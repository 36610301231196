import jwt from "jsonwebtoken"

export async function decodeToken(): Promise<any> {
  let token = window.localStorage.getItem('TOKEN');

  if (!token) {
    return {
      w: '',
    }
  }

  let decoded: any = jwt.decode(token);

  if (decoded.exp * 1000 < Date.now()) {
    window.localStorage.removeItem('TOKEN');
    // window.location.href = '/login';
  }

  return decoded;
}

export async function getWalletAddress() {
  return (await decodeToken()).w;
}