import Endpoint from '../Endpoint';

export default class KycEndpointed extends Endpoint {
  async submit(data, token) {
    return await this.axiosWrapper
      .post(`/submitKyc`, data, { headers: { Authorization: 'Bearer ' + token }})
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async fetchRawDevice(data) {
    return await this.axiosWrapper
      .post(`/fetchRawDevice`, data)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async submitKycByMod(data) {
    return await this.axiosWrapper
      .post(`/submitKycByMod`, data)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
