import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import GlobalStyle from 'styles/global';
import ScrollToTop from 'context/ScrollToTop';
import Loading from 'components/Layout/Loading';
import LazyLoading from 'LazyLoading';
import { usePollBlockNumber } from 'state/block/hooks';
import useEagerConnect from 'hooks/useEagerConnect';
import useVerifyToken from 'hooks/useVerifyToken';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'colors/theme';
import { useThemeType } from 'state/theme/hooks';

import Home from 'feature/home';
import Kyc from 'feature/kyc';
import PopupBar from 'components/Popup';
import Layout from 'components/Layout';
import Login from 'feature/Auth/Login';
import AuthLayout from 'components/Layout/AuthLayout';
import AnimationWrapper from 'styles/animationWrap';
import AdminLayout from 'components/Layout/Admin';
import AdminUser from 'feature/Admin/User';
import AntDConfig from 'styles/antdConfig';
import UserDetails from 'feature/Admin/User/UserDetail';
import ConfirmModal from 'components/ConfirmModal';
import ForgetPassword from 'feature/Auth/ForgetPassword';
import ResetPassword from 'feature/Auth/ResetPassword';
import TermAndCondition from 'feature/term';
import AdminReward from 'feature/Admin/Reward';

// const Home = LazyLoading(import('feature/home'));
// const Kyc = LazyLoading(import('feature/kyc'));

const App = () => {
  const location = useLocation();
  console.log(location);

  // hook
  // usePollBlockNumber();
  // useEagerConnect();
  // useVerifyToken();

  const theme = useThemeType() === 'light' ? lightTheme : darkTheme;

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <GlobalStyle />
        <AnimationWrapper />
        <AntDConfig />
        <PopupBar />
        <ConfirmModal />
        {/* <Suspense fallback={<Loading />}> */}
        <Routes>
          <Route path="/dashboard" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/register" element={<Kyc />} />
          <Route path="/termcondition" element={<TermAndCondition />} />

          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Navigate to="/login" />} />
            <Route path="login" element={<Login />} />
            <Route path="forgetpassword" element={<ForgetPassword />} />
            <Route path="resetpassword" element={<ResetPassword />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />

          <Route path="/admin/dashboard" element={<AdminLayout />}>
            <Route index element={<AdminUser />} />
            <Route path="user" element={<AdminUser />} />
            <Route path="user/:id" element={<UserDetails edit={false} />} />
            <Route path="user/edit/:id" element={<UserDetails edit={true} />} />
            <Route path="reward" element={<AdminReward />} />
          </Route>

          {/* <Route path="*" element={<NoMatch />} /> */}
        </Routes>
        {/* </Suspense> */}
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
