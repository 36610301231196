import { Input } from 'antd';
import { SimpleComponent } from 'interface';
import React from 'react';
import { InputProps } from 'antd/lib/input/Input';
import styled from 'styled-components';
import { PasswordProps } from 'antd/lib/input/Password';

interface Props extends SimpleComponent, InputProps, PasswordProps {}

const InputWrapper = styled.div`
  .ant-input-suffix svg {
    color: ${({ theme }) => theme.main};
  }
  .ant-input-affix-wrapper {
    padding: 0.8rem 1rem;

    font-size: 1.4rem;
    outline: 0;
    border-width: 1px;
    background-color: none;
    border-radius: 0.5rem;
    border-color: ${({ theme }) => theme.gray_2};
    background-color: ${({ theme }) => theme.box};
    color: ${({ theme }) => theme.gray_8};
  }
  input {
    padding: 0.8rem 1rem;

    font-size: 1.4rem;
    border-radius: 0.5rem;
    outline: 0;
    border-color: ${({ theme }) => theme.gray_2};
    background-color: ${({ theme }) => theme.box};
    color: ${({ theme }) => theme.gray_8};
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }
  .ant-input:hover,
  .ant-input-hover {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    border-color: ${({ theme }) => theme.gray_2};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    border-color: ${({ theme }) => theme.gray_2};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.main} !important;
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }
`;

export function InputPassword(props: Props) {
  return (
    <InputWrapper className={props.className} style={props.style}>
      <Input.Password {...props} style={null} className="" />
    </InputWrapper>
  );
}

export default InputPassword;
