import {
  FlexColumn,
  FlexRow,
  GridColumn,
  Text,
  TextH1,
  TextHover,
} from 'components/box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from 'components/Image';
import { Form } from 'antd';
import InputPassword from 'components/Input/Password';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Input from 'components/Input';
import Button from 'components/Button';
import { device } from 'styling/fast';
import { Link, useNavigate } from 'react-router-dom';
import CheckboxStyled from 'components/Input/Checkbox';
import { useAppDispatch } from 'state';
import usePopup from 'hooks/usePopup';
import { useProfile } from 'state/profile/hooks';
import { apiPublic } from 'Api';
import { LOGIN } from 'state/profile/action';
import useConfirm from 'hooks/useConfirm';
import { toast } from 'react-toastify';

const md5 = require('md5');

const LoginWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_container {
    width: 50rem;
  }
  .logo {
    width: 10rem;
    height: 10rem;
  }
  .form_item {
    input {
      font-size: 2rem;
    }
  }
  @media ${device.laptop} {
    .login_container {
      width: 90%;
    }
    .form_item {
      input {
        font-size: 2.5rem;
      }
    }
  }
`;

function AuthLogin() {
  const [form] = Form.useForm<LoginForm>();
  const dispatch = useAppDispatch();
  const popup = usePopup();
  const profile = useProfile();
  const navigate = useNavigate();
  const { isConfirmed } = useConfirm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const result = await apiPublic.auth.login({
      ...values,
      password: md5(values.password),
    });
    if (result.success && result.data) {
      toast.success('You have successfully logged in.');
      dispatch(
        LOGIN({
          account: result.data.email,
          data: result.data.data,
          token: result.data.token,
          isAdmin: result.data.data.isAdmin,
        })
      );
    } else {
      popup.error({ title: 'Login', text: result.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile) {
      if (profile.islogin && !profile.isAdmin) {
        navigate('/dashboard');
      } else if (profile.islogin && profile.isAdmin) {
        navigate('/admin/dashboard/user');
      }
    }
  }, [profile]);

  return (
    <LoginWrapper>
      <GridColumn className="login_container">
        <FlexRow>
          <Image
            className="logo bounce"
            src="/image/logo/logo.svg"
            alt="climate-positive-logo"
          />
        </FlexRow>
        <Form
          onFinish={onFinish}
          autoComplete="on"
          form={form}
          layout={'vertical'}
        >
          <Form.Item
            className="form_item"
            label={<Text>Username</Text>}
            name="username"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input size={'middle'} />
          </Form.Item>
          <FlexColumn>
            <Form.Item
              className="form_item"
              label={<Text>Password</Text>}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <InputPassword
                size={'middle'}
                iconRender={(visible) =>
                  visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
                }
              />
            </Form.Item>
            {/* <FlexRow style={{ justifyContent: 'space-between' }}>
              <Form.Item
                className="form_item"
                name="save"
                valuePropName="checked"
                required={false}
              >
                <CheckboxStyled label="Save password" />
              </Form.Item>

              <Form.Item
                className="form_item"
                name="remember"
                required={false}
                valuePropName="checked"
              >
                <CheckboxStyled label="Remember me " />
              </Form.Item>
            </FlexRow> */}

            <FlexRow style={{ justifyContent: 'space-between' }}>
              <Link to="/register">
                <TextHover text="Sign Up" />
              </Link>
              <Link to="/forgetpassword">
                <TextHover text="Forget password" />
              </Link>
            </FlexRow>
          </FlexColumn>

          <Form.Item className="form_item">
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              type="submit"
              loading={loading}
              text="LOGIN"
            />
          </Form.Item>
        </Form>
      </GridColumn>
    </LoginWrapper>
  );
}

export default AuthLogin;
