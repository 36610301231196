import styled from 'styled-components';
import { device } from 'styling/fast';

export const UserDetailsWrapper = styled.div`
  width: 100%;
  .form_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    row-gap: 0;
  }
  .form_button {
    grid-column: 1 /-1;
  }
  @media ${device.laptop} {
    .form_container {
      grid-template-columns: 1fr;
    }
  }
  .ant-form .ant-form-item .ant-form-item-label {
    flex: none;
  }
  .form_item {
    flex-wrap: nowrap;
  }
`;
