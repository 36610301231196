import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FlexRow, Text, TextB } from 'components/box';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const SolorEstimatedWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const LabelWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
`;

function SolorEstimated({ current, total }) {
  const theme = useTheme();

  const data = [
    {
      name: 'Solar Generated',
      description: current.toFixed(2) + ' MWh',
      value: (current / total) * 100,
      color: theme.graph2_2,
    },
    {
      name: 'Target Efficiency',
      description: total.toFixed(2) + ' MWh',
      value: Math.max(100 - (current / total) * 100, 0),
      color: theme.minimal,
    },
  ];

  console.log(data);

  return (
    <SolorEstimatedWrapper className="box">
      <TextB>Solar Generated VS Target Efficiency</TextB>
      <FlexRow style={{ width: '100%', height: '100%' }}>
        <LabelWraper>
          <Text>{data[0].value.toFixed(2)}%</Text>
          <Text>{data[0].description} /</Text>
          <Text>{data[1].description}</Text>
        </LabelWraper>
        <ResponsiveContainer width={'100%'} height={'80%'}>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={theme.graph2_2}
              strokeWidth={0}
              startAngle={90}
              endAngle={458}
            >
              {data.map((e, index) => (
                <Cell
                  color={theme.textp}
                  key={`slice-${index}`}
                  fill={data[index].color}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </FlexRow>
    </SolorEstimatedWrapper>
  );
}

export default SolorEstimated;
