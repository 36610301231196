import { Form } from 'antd';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import scrollForce from 'utils/scrollForce';
import { getWalletAddress } from 'utils/token';
import KycStep1 from './KycStep1';
import KycStep2 from './KycStep2';
import KycStep3 from './KycStep3';
import KycStep4 from './KycStep4';
import KycStep5 from './KycStep5';
import KycStep6 from './KycStep6';
import LayoutKyc from './LayoutKyc';

// const initialState: dataForm = {
//   role: '',
//   name: 'test',
//   email: 'ff@gmail.com',
//   country: 'Thailand',
//   username: 'frong',
//   password: 'Asdf1234567890*',
//   confirmpassword: 'Asdf1234567890*',
//   agreement: true,
//   installationDate: null,
//   installedCapacity: '100',
//   type: 'Self ownership',
//   period: '100',
//   picture: [],
//   location: '',
//   // wallet
//   walletAddress: '',

//   siteId: '',
//   projectCode: '',
//   apiKey: '',

//   profilePicture: '',
// };

const initialState: dataForm = {
  role: '',
  name: '',
  email: '',
  country: '',
  username: '',
  password: '',
  confirmpassword: '',
  agreement: false,
  installationDate: null,
  installedCapacity: '',
  type: '',
  period: '',
  picture: [],
  location: '',
  // wallet
  walletAddress: '',

  siteId: '',
  projectCode: '',
  apiKey: '',

  profilePicture: '',
};

function Index() {
  const apiServer = useApi();
  let [current, setCurrent] = useState(1);
  let [success, setSucces] = useState(0);
  let [isAutofill, setIsAutofill] = useState(false);

  let [error, setError] = useState('');

  const [data, setData] = useState<dataForm>(initialState);
  const [form] = Form.useForm<dataForm>();

  const onChangeData = (e) => {
    form.setFieldsValue(e);
  };

  const onChangeCurrent = (st) => {
    setCurrent(st);
  };

  const props = {
    success,
    setSuccess: setSucces,
    setCurrent,
    form,
    data,
    setData,
    current,
    onChangeData,
    onChangeCurrent,
    error,
    setError,
    isAutofill,
  };

  useEffect(() => {
    scrollForce(0);
  }, [current]);

  async function fetchInitialData() {
    let walletAddress = await getWalletAddress();
    if (walletAddress) {
      let response = await apiServer.device.getByWallet(walletAddress);
      console.log(response.data);

      setData({
        ...data,
        name: response.data.name,
        username: response.data.username,
        email: response.data.email,
        country: response.data.kycData.country,
        profilePicture: response.data.kycData.profilePicture,
      });

      setIsAutofill(true);
    }
  }

  function getPercent() {
    let step = Math.min(current, 5);
    if (data.role == 'tokenholder') {
      if (step < 3) return (step / 4) * 100;
      return ((step - 1) / 4) * 100;
    } else {
      return (step / 5) * 100;
    }
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <LayoutKyc {...props} percent={getPercent()}>
      <React.Fragment>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 1}
          classNames="fade"
          timeout={200}
        >
          <KycStep1 {...props} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 2}
          classNames="fade"
          timeout={200}
        >
          <KycStep2 {...props} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 3}
          classNames="fade"
          timeout={200}
        >
          <KycStep3 {...props} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 4}
          classNames="fade"
          timeout={200}
        >
          <KycStep4 {...props} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 5}
          classNames="fade"
          timeout={200}
        >
          <KycStep5 {...props} />
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          mountOnEnter
          in={current === 6}
          classNames="fade"
          timeout={200}
        >
          <KycStep6 {...props} />
        </CSSTransition>
      </React.Fragment>
    </LayoutKyc>
  );
}

export default Index;
