import React from 'react';
import { TextH1 } from 'components/box';
import { Text } from 'components/box';
import styled from 'styled-components';
import { device } from 'styling/fast';

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-height: 80vh;
  padding: 2rem;
  overflow: scroll;
  @media ${device.laptop} {
    width: auto;
  }
`;

function TermAndCondition() {
  return (
    <StyledFlex>
      <TextH1 size={'xl'}>Term & Conditions</TextH1>
      <Text size="lg" style={{ marginBottom: '2rem' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This
        Litepaper is provided for your information only and does not constitute
        investment advice. It should also not be construed as an offer,
        solicitation or invitation to earn or purchase (when available) DiM
        Tokens. Under no circumstances should DiM Tokens be deemed an
        investment. DiM Tokens are not shares, bonds, units in a collective
        investment scheme, securities, or other instruments commonly known as
        securities of any type. The publication, distribution or dissemination
        of this LitePaper does not imply that the applicable laws, regulatory
        requirements or rules have been complied with. The distribution of this
        LitePaper and the offer and sale of the Tokens in certain jurisdictions
        may be restricted by law hence this LitePaper, any part and any copy
        thereof must not be taken or transmitted to any country where
        distribution or dissemination of this LitePaper is prohibited or
        restricted.
      </Text>

      <Text size="lg" style={{ marginBottom: '2rem' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is the
        responsibility of any persons wishing to acquire the Tokens to inform
        themselves of and to observe all applicable laws and regulations of any
        relevant jurisdictions as well as legal requirements and tax
        consequences within the countries of their citizenship, residence,
        domicile, and place of business with respect to the acquisition, holding
        or disposal of the Tokens, and any restrictions that may be relevant
        thereto. The participation to DiM Tokens in any kind is under your own
        consideration. The prices of tokens can be highly volatile, it is highly
        recommended to consult legal, financial, tax and other professional
        advisers or experts for further guidance before you decide to have any
        transactions related to DiM Tokens and its platform. The Tokens only
        offered to interested parties in those jurisdictions and to those
        persons where and to whom they lawfully may be offered for sale.
      </Text>

      <Text size="lg">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The
        information in this Litepaper may subject to change as time passed, the
        issuer has no responsibility to update those information. the issuer
        does not hold responsibility nor assurance of the accuracy, completeness
        and no representation of any information either current or overtimes.
        Some information or content in either the Litepaper or Website
        (climatepositive.ai) could be obtained from third parties, or public
        resources that the issuer or its development team have not independently
        verified the accuracy or completeness of such information. The issuer,
        the development team, stakeholders as well as other service providers
        related to DiM Tokens and its platform/ Website are not liable for
        direct and indirect losses as consequences of your participation in any
        kind of the Tokens, accessing to this Litepaper or utilizing any
        information gather from this Litepaper, the Website or any other website
        or materials published by the Issuer. DiM Tokens do not entitle you to
        any equity, governance, voting or similar right or interest in the
        issuer operation or in any of its Affiliates. Changes in circumstances
        might cause the project and DiM Token features described in this
        Litepaper to change, or end altogether. DiM Tokens are not being offered
        to US Persons and are not available in the United States, sanctioned
        jurisdictions or in other jurisdictions as may be specified by DiM from
        time to time. If you are located in, incorporated or otherwise
        established in, or a resident of the United States, you are not
        permitted to transact in DiM Tokens. In addition, certain benefits and
        features associated with DiM Tokens may not be available in certain
        jurisdictions
      </Text>
    </StyledFlex>
  );
}

export default TermAndCondition;
