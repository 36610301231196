import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AiOutlineClose, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FlexRow } from 'components/box';
import StyledModalContainer from 'components/StyledModalContainer';
import useConfirm from 'hooks/useConfirm';
import Button from 'components/Button';
import Input from 'components/Input';
import { BsCheckCircleFill } from 'react-icons/bs';
import colors from 'colors/colors';

const ConfirmModal = () => {
  const { state, confirm, setConfirm } = useConfirm();
  const valueToset = state.value;

  const [value, setValue] = useState('');

  const onChangeValue = (e) => {
    if (state.type === 'number') {
      if (Number(e.target.value) >= 0) {
        if (state.max) {
          if (Number(e.target.value) <= state.max) {
            setValue(e.target.value);
          }
        } else {
          setValue(e.target.value);
        }
      }
    } else {
      setValue(e.target.value);
    }
  };

  const onconfirm = () => {
    setConfirm({ ...confirm, isOpen: false });
    confirm.proceed({ confirm: true, value });
  };

  const onreject = () => {
    setConfirm({ ...confirm, isOpen: false });
    confirm.cancel({ confirm: false });
  };

  useEffect(() => {
    if (confirm.isOpen) {
      setValue(valueToset);
    }
  }, [confirm.isOpen]);

  return (
    <React.Fragment>
      <CSSTransition
        in={confirm.isOpen}
        timeout={200}
        classNames="fade"
        unmountOnExit
        mountOnEnter
      >
        <StyledModalContainer tone={'purple'}>
          <div className="bgDark_modal" onClick={onreject} />
          <div className={`container_modal`}>
            <div onClick={onreject} className="exit_btn">
              <AiOutlineClose />
            </div>
            <h1 className="title_text">
              {/* <AiOutlineLoading3Quarters
                style={{ marginRight: '2rem', fontSize: '3rem' }}
                className="spining"
              /> */}
              {state.title ? state.title : 'Confirmation'}
            </h1>
            <div className="content_modal">
              {!state.input && (
                <FlexRow>
                  <BsCheckCircleFill
                    color={colors.green.primary}
                    className="icon"
                  />
                </FlexRow>
              )}

              {state.text && (
                <FlexRow
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.8rem',
                    paddingTop: '1rem',
                  }}
                >
                  {state.text}
                </FlexRow>
              )}

              <br />

              {state.input && (
                <FlexRow
                  style={{ width: '80%', margin: '0 auto', marginTop: '1rem' }}
                >
                  <Input
                    type={state.type}
                    style={{ width: '100%' }}
                    value={value}
                    onChange={onChangeValue}
                    max={state.max}
                  />
                </FlexRow>
              )}
              <br />

              <FlexRow
                style={{ marginTop: '1rem', justifyContent: 'space-between' }}
              >
                <Button
                  style={{
                    width: '40%',
                    margin: '0 auto',
                    fontSize: '1.6rem',
                    padding: '0.8rem 0',
                  }}
                  onClick={onconfirm}
                  tone={'blue'}
                  text="Confirm"
                  disabled={state.input ? value === '' : false}
                />
                {!state.noCancel && (
                  <Button
                    style={{
                      width: '40%',
                      margin: '0 auto',
                      fontSize: '1.6rem',
                      padding: '0.8rem 0',
                    }}
                    onClick={onreject}
                    tone={'red'}
                    text="Cancel"
                    disabled={false}
                  />
                )}
              </FlexRow>
            </div>
          </div>
        </StyledModalContainer>
      </CSSTransition>
    </React.Fragment>
  );
};
export default ConfirmModal;
