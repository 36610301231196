import { SimpleComponent } from 'interface';
import React, { ReactChild } from 'react';
import styled from 'styled-components';

interface Props extends SimpleComponent {
  children: ReactChild;
}

const SelectoptionWrapper = styled.div`
  .ant-select {
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 4.4rem;
    font-size: 1.4rem;
    border-radius: 0.5rem;
    padding: 0 1rem;
    outline: 0;
    border-color: ${({ theme }) => theme.gray_2};
    background-color: ${({ theme }) => theme.box};
    color: ${({ theme }) => theme.gray_8};
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: ${({ theme }) => theme.gray_2};
  }

  .ant-select-disabled .ant-select-arrow {
    svg {
      color: ${({ theme }) => theme.gray_2};
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.main} !important;
    box-shadow: none !important;
    border-width: 1 !important;
    outline: 0 !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .dropdown {
    background-color: ${({ theme }) => theme.gray_2};
  }

  svg {
    color: ${({ theme }) => theme.main};
  }

  .ant-select-selection-search input {
    height: 100% !important;
    font-size: 1.4rem !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  /* .ant-input:focus,
  .ant-input-focused {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }
  .ant-input:hover,
  .ant-input-hover {
    border-color: ${({ theme }) => theme.main};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  } */
`;

function Selectoption(props: Props) {
  return <SelectoptionWrapper>{props.children}</SelectoptionWrapper>;
}

export default Selectoption;
