import colors from 'colors/colors';
import styled from 'styled-components/macro';
import { flexAlign, toneColor } from 'styling/fast';

export const ButtonActive = styled.button<{ active?: boolean }>`
  border: 0;
  width: 7rem;
  height: 3rem;
  border-radius: 0.2rem;
  background-color: ${({ active, theme }) => (!active ? theme.bg : theme.main)};
  color: ${({ active, theme }) => (active ? 'white' : theme.text)};
  border: 0.5px solid;
  border-color: ${({ active, theme }) => (active ? theme.main : theme.border)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  ${flexAlign}
  :hover {
    opacity: 0.8;
  }
`;
