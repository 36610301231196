import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import styled from 'styled-components';
import { SimpleComponent } from 'interface';

const CheckboxWrapper = styled.div`
  .ant-checkbox + span {
    color: ${({ theme }) => theme.textp};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.main};
  }
`;

interface Props extends SimpleComponent, CheckboxProps {
  label?: React.ReactNode;
}

function CheckboxStyled(props: Props) {
  return (
    <CheckboxWrapper>
      <Checkbox {...props}>{props.label}</Checkbox>
    </CheckboxWrapper>
  );
}

export default CheckboxStyled;
