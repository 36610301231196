import Endpoint from '../Endpoint';

export default class RewardEndpointed extends Endpoint {
  async rejectPendingWithdraw(data) {
    return await this.axiosWrapper
      .post(`/rejectPendingWithdraw`, data)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
