import colors from 'colors/colors';
import { FontSize } from 'interface';
import styled from 'styled-components/macro';
import { flexAlign, imageBg } from 'styling/fast';

const fontTranslate = (size: string) => {
  if (size === 'xs') {
    return '0.8rem';
  } else if (size === 'sm') {
    return '1rem';
  } else if (size === 'md') {
    return '1.2rem';
  } else if (size === 'base') {
    return '1.4rem';
  } else if (size === 'lg') {
    return '1.6rem';
  } else if (size === 'xl') {
    return '2rem';
  } else if (size === '2xl') {
    return '2.5rem';
  } else {
    return size;
  }
};

export const AutoRow = styled.div`
  width: 100%;
  padding: 0 20px;
  button {
    font-size: 20px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridColumn = styled.div<{ gap?: string }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  place-content: flex-start;
  grid-gap: ${({ gap }) => (gap ? gap : '1rem')};
`;

export const GridRow = styled.div<{
  gap?: string;
  rowTem?: string;
  min?: string;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ rowTem, min }) =>
    rowTem
      ? rowTem
      : !min
      ? 'repeat( auto-fit, minmax(250px, 1fr) )'
      : `repeat( auto-fit, minmax(${min}, 1fr) )`};
  grid-template-rows: 1fr;
  place-content: flex-start;
  grid-gap: ${({ gap }) => (gap ? gap : '1rem')};
`;

export const BoxShadow = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  padding: 2rem 2rem;
`;

export const TextH1 = styled.h1<FontSize>`
  font-weight: ${({ weight }) => (weight ? weight : 700)};
  text-align: ${({ align }) => (align ? align : 'left')};
  font-size: ${({ size }) => (size ? fontTranslate(size) : '2.5rem')};
  color: ${({ color, theme }) => (color ? color : theme.text)};
  margin: 0;
`;

export const TextB = styled.b<FontSize>`
  font-weight: ${({ weight }) => (weight ? weight : 500)};
  text-align: ${({ align }) => (align ? align : 'left')};
  font-size: ${({ size }) => (size ? fontTranslate(size) : '1.4rem')};
  color: ${({ color, theme }) => (color ? color : theme.text)};
  margin: 0;
`;

export const TextH2 = styled.h2<FontSize>`
  font-weight: ${({ weight }) => (weight ? weight : 500)};
  text-align: ${({ align }) => (align ? align : 'left')};
  font-size: ${({ size }) => (size ? fontTranslate(size) : '2.5rem')};
  color: ${({ color, theme }) => (color ? color : theme.text)};
  margin: 0;
`;

export const Text = styled.p<FontSize>`
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  text-align: ${({ align }) => (align ? align : 'left')};
  font-size: ${({ size }) => (size ? fontTranslate(size) : '1.4rem')};
  color: ${({ color, theme }) => (color ? color : theme.textp)};
  margin: 0;
`;

export const SocialButton = styled.div<{
  image?: string;
  height?: string;
  width?: string;
}>`
  width: ${({ width }) => (width ? width : '4rem')};
  height: ${({ height }) => (height ? height : '4rem')};
  background-image: ${({ image }) =>
    image ? `url(${image})` : 'url("image/social/red.webp")'};
  ${imageBg}
  display: flex;
  ${flexAlign}
  font-size: 2rem;
  color: white;
  opacity: 1;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const LinkExternalWrapper = styled.a`
  * {
    color: white;
  }
  :hover {
    * {
      color: ${colors.main[5]};
    }
  }
`;

const TextHoverWrapper = styled(Text)`
  color: ${({ theme }) => theme.main};
  /* text-decoration: ${({ theme }) => theme.main} underline dotted; */
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const LinkExternal = ({
  text,
  link,
  size,
}: {
  text: string;
  link: string;
  size?: string;
}) => {
  return (
    <LinkExternalWrapper target="_blank" rel="noreferrer" href={link}>
      <Text size={fontTranslate(size) || '1.4rem'}>{text}</Text>
    </LinkExternalWrapper>
  );
};

export const TextHover = ({
  size,
  text,
  className,
  style,
  onClick,
}: {
  size?: string;
  text: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => any;
}) => {
  return (
    <TextHoverWrapper
      onClick={onClick}
      style={style}
      className={className}
      size={fontTranslate(size) || '1.6rem'}
    >
      {text}
    </TextHoverWrapper>
  );
};

export const TextTitle = styled.h1`
  text-align: center;
  font-size: 8rem;
  color: white;
  font-weight: 300;
  text-shadow: 0.3rem 0.3rem ${colors.main[5]};
  margin: 4rem 0;
`;

export const ActiveButton = styled.button<{ active?: boolean }>`
  font-size: 1.4rem;
  border-radius: 0.4rem;
  padding: 1rem 2rem;
  border: solid 0.2rem ${colors.main[5]};
  background-color: ${({ active }) => (active ? colors.main : 'transparent')};
  color: white;
  cursor: pointer;
  display: flex;

  ${flexAlign}
  :disabled {
    color: white;
    background-color: ${colors.main[5]};
    cursor: not-allowed;
    opacity: 0.8;
  }
  :hover {
    background-color: ${colors.main[5]};
  }
`;
