// theme.ts
import { DefaultTheme } from 'styled-components/macro';
import colors from './colors';
export const lightTheme: DefaultTheme = {
  bg: '#f5f5f5',
  nav: '#fff',
  menu: '#a9abac',
  minimal: '#d9d9d9',
  box: '#fff',
  border: '#e5e5e5',
  text: '#4a4a4a',
  main: '#671cc9',
  textp: '#a2a5b9',
  gray_2: colors.gray[2],
  gray_4: colors.gray[4],
  gray_8: colors.gray[8],
  red_2: colors.red[2],
  blue_2: colors.blue[2],
  geekblue_2: colors.geekblue[2],
  graph1_1: '#FFAA81',
  graph1_2: '#E29877',
  graph2_1: '#797BF1',
  graph2_2: '#867EE0',
  separator: '#e5e5e5',

  red: colors.red.primary,
  blue: colors.blue.primary,
  orange: '#ffa981',
  green: '#93e088',
  pink: '#ff227d',
  purple: '#671cc9',
};

export const darkTheme: DefaultTheme = {
  bg: '#3b3b41',
  nav: '#1e1e21',
  menu: '#d4d4d4',
  minimal: '#232326',
  box: '#323236',
  border: '#404046',
  text: '#aaaab3',
  main: '#797bf2',
  textp: '#a2a5b9',
  gray_2: colors.gray[8],
  gray_4: colors.gray[6],
  gray_8: colors.gray[2],
  red_2: colors.red[8],
  blue_2: colors.blue[8],
  geekblue_2: colors.geekblue[8],
  graph1_1: '#FFAA81',
  graph1_2: '#E29877',
  graph2_1: '#797BF1',
  graph2_2: '#867EE0',
  separator: '#4a4a50',

  red: colors.red.primary,
  blue: colors.blue.primary,
  orange: '#ffa981',
  green: '#93e088',
  pink: '#ff227d',
  purple: '#671cc9',
};
