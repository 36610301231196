import {
  FlexColumn,
  FlexRow,
  GridColumn,
  Text,
  TextH1,
  TextHover,
} from 'components/box';
import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'components/Image';
import { Form } from 'antd';
import InputPassword from 'components/Input/Password';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Input from 'components/Input';
import Button from 'components/Button';
import { device } from 'styling/fast';
import { Link } from 'react-router-dom';
import CheckboxStyled from 'components/Input/Checkbox';
import usePopup from 'hooks/usePopup';
import { apiPublic } from 'Api';

const LoginWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .login_container {
    width: 50rem;
  }
  .logo {
    width: 10rem;
    height: 10rem;
  }
  .form_item {
    input {
      font-size: 2rem;
    }
  }
  @media ${device.laptop} {
    .login_container {
      width: 90%;
    }
    .form_item {
      input {
        font-size: 2.5rem;
      }
    }
  }
  .back_btn {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 3rem;
  }
`;

function ForgetPassword() {
  const [form] = Form.useForm<LoginForm>();
  const popUp = usePopup();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    const result = await apiPublic.auth.requestPassword(values);
    if (result.success) {
      popUp.success({
        text: 'Request reset password success! : Please check your inbox',
      });
      form.resetFields(['email']);
    } else {
      popUp.error({ text: result.message });
    }
    setLoading(false);
  };

  return (
    <LoginWrapper>
      <Link to="/">
        <TextHover className="back_btn" text="BACK" />
      </Link>
      <GridColumn className="login_container">
        <FlexRow>
          <Image
            className="logo bounce"
            src="/image/logo/logo.svg"
            alt="climate-positive-logo"
          />
        </FlexRow>
        <TextH1 style={{ textAlign: 'center', margin: '1rem 0' }}>
          Forget Password
        </TextH1>
        <Form
          onFinish={onFinish}
          autoComplete="on"
          form={form}
          layout={'vertical'}
        >
          <Form.Item
            className="form_item"
            label={<Text>Type your email to reset password</Text>}
            name="email"
            hasFeedback
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input size={'middle'} />
          </Form.Item>

          <Form.Item className="form_item">
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              type="submit"
              loading={loading}
              text="SUBMIT"
            />
          </Form.Item>
        </Form>
      </GridColumn>
    </LoginWrapper>
  );
}

export default ForgetPassword;
