import React from 'react';
import { LayoutContentWrapper } from './styled';
import TopNavbar from './TopNavbar';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  title?: string;
  showNav?: boolean;
}

function LayoutContent({
  children,
  className,
  style,
  width = '90%',
  title,
  showNav = true,
}: Props) {
  return (
    <LayoutContentWrapper width={width} style={style} className={className}>
      {showNav && <TopNavbar title={title} />}
      {children}
    </LayoutContentWrapper>
  );
}

export default LayoutContent;
