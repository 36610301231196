import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FlexRow, Text, TextB } from 'components/box';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const PowerConsumationWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const LabelWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 20%;
`;

function PowerConsumation({ current, total }) {
  const theme = useTheme();

  const data = [
    {
      name: 'Community Size',
      description: Math.ceil(total) + ' kW',
      value: Math.max(100 - (current / total) * 100, 0),
      color: theme.minimal,
    },
    {
      name: 'System Size',
      description: Math.ceil(current) + ' kW',
      value: (current / total) * 100,
      color: theme.graph1_2,
    },
  ];

  console.log(data);

  return (
    <PowerConsumationWrapper className="box">
      <TextB>System Size VS Community Size</TextB>
      <FlexRow style={{ width: '100%', height: '100%', position: 'relative' }}>
        <LabelWraper>
          <Text>{data[1].value.toFixed(2)}%</Text>
          <Text>{data[1].description}</Text>
        </LabelWraper>
        <ResponsiveContainer width={'100%'} height={'80%'}>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={theme.graph2_2}
              strokeWidth={0}
              startAngle={135 + 180}
              endAngle={405 + 180}
            >
              {data.map((e, index) => (
                <Cell
                  color={theme.textp}
                  key={`slice-${index}`}
                  fill={data[index].color}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </FlexRow>
    </PowerConsumationWrapper>
  );
}

export default PowerConsumation;
