import { FlexRow, TextH1 } from 'components/box';
import React from 'react';
import { TopNavbarWrapper } from './styled';
import { Divide as Hamburger } from 'hamburger-react';
import { useTheme } from 'styled-components';
import ToggleTheme from 'components/ToggleTheme';
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton';
import { useAppDispatch } from 'state';
import { TOGGLESIDEBAR } from 'state/layout/reducer';
import { useSideBar } from 'state/layout/hooks';
import useResponsive from 'hooks/useResponsive';
import Image from 'components/Image';
import { useProfile } from 'state/profile/hooks';

function TopNavbar({ title }: { title?: string }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const sideBar = useSideBar();
  const profile = useProfile();

  const isMobile = useResponsive('mobileL');

  const toggleMenu = () => {
    dispatch(TOGGLESIDEBAR());
  };

  return (
    <TopNavbarWrapper>
      <FlexRow>
        <Hamburger
          toggled={sideBar}
          onToggle={toggleMenu}
          color={theme.main}
          size={isMobile ? 20 : 30}
        />
        {!isMobile && <TextH1 style={{ marginLeft: '0.5rem' }}>{title}</TextH1>}
      </FlexRow>
      <FlexRow>
        {!isMobile && <ToggleTheme style={{ marginRight: '1rem' }} />}
        {isMobile && (
          <FlexRow>
            <Image
              className="logo"
              src="/image/logo/logo.svg"
              alt="climate-positive-logo"
            />
          </FlexRow>
        )}
        {isMobile && <ToggleTheme style={{ margin: '1rem' }} />}
        {!Boolean(profile.isAdmin) && <ConnectWalletButton />}
      </FlexRow>
    </TopNavbarWrapper>
  );
}

export default TopNavbar;
