import colors from 'colors/colors';
import { AiOutlineLoading } from 'react-icons/ai';
import styled from 'styled-components/macro';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  style?: React.CSSProperties;
  onClick?: (e: any) => any;
  text: string;
  disabled?: boolean;
  tone?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  loading?: any;
}

export const ButtonWrapper = styled.button<{ color?: string }>`
  width: auto;
  background: ${({ color, theme }) => (color ? color : theme.main)};
  border: 0;
  height: auto;
  font-size: 1.6rem;
  padding: 1rem 4rem;
  color: white;
  border-radius: 1rem;
  cursor: pointer;
  opacity: 0.9;
  :hover {
    opacity: 1;
  }
  :disabled {
    background-color: ${({ theme }) => theme.minimal};
    color: ${({ theme }) => theme.text};
    cursor: not-allowed;
  }
`;

const Button = ({
  className,
  disabled,
  onClick,
  style,
  color,
  text,
  type,
  loading,
  ...others
}: Props) => {
  return (
    <ButtonWrapper
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
      type={type}
      color={color}
      {...others}
    >
      {loading ? <AiOutlineLoading className="spining" /> : text}
    </ButtonWrapper>
  );
};

export default Button;
