import { Text } from 'components/box';
import { colorBadge, FontSize, SimpleComponent } from 'interface';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 1.5em;
`;

interface Props extends SimpleComponent {
  children?: React.ReactNode;
  text?: string;
  color?: colorBadge;
}

function Badge({ style, color, className, text, children }: Props) {
  const theme = useTheme();

  return (
    <BadgeWrapper
      style={{ background: color ? theme[color] : theme.main, ...style }}
      className={className}
    >
      {text ? (
        <Text size="md" style={{ color: 'white' }}>
          {text}
        </Text>
      ) : children ? (
        children
      ) : null}
    </BadgeWrapper>
  );
}

export default Badge;
