import { FlexColumn, FlexRow, TextH2 } from 'components/box';
import Button from 'components/Button';
import { DEFAULT_META, getCustomMeta } from 'config/meta';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
// import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSideBar } from 'state/layout/hooks';
import { LOGOUT } from 'state/profile/action';
import { useProfile, useToken } from 'state/profile/hooks';
import SideNavbar from './SideNavbar';
import { LayoutWrapper } from './styled';

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { pathname } = useLocation();
  const pageMeta = getCustomMeta(pathname);
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

function Layout() {
  const sideBar = useSideBar();
  const profile = useProfile();
  const navigate = useNavigate();
  const token = useToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile.islogin) {
      navigate('/login');
    } else {
      if (profile.isAdmin) {
        navigate('/admin/dashboard');
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!token) {
      dispatch(LOGOUT());
    }

    setTimeout(() => {
      if (!window.localStorage.getItem('TOKEN')) {
        dispatch(LOGOUT());
      }
    }, 500);
  }, [token]);

  return (
    <LayoutWrapper sideBar={sideBar}>
      <PageMeta />
      <div className="content">
        {profile.islogin &&
          profile.data &&
          profile.data.kycStatus === 'approved' &&
          profile.data.role === 'systemowner' && <Outlet />}
        {profile.islogin &&
          profile.data &&
          profile.data.kycStatus === 'pending' &&
          profile.data.role === 'tokenholder' && (
            <FlexColumn
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10rem',
              }}
            >
              <TextH2 style={{ marginBottom: '1rem' }}>
                Change to System Owner
              </TextH2>
              <Link to="/register">
                <Button
                  text="Go to KYC"
                  disabled={false}
                  style={{ width: '30rem' }}
                />
              </Link>
            </FlexColumn>
          )}
        {profile.islogin &&
          profile.data &&
          profile.data.kycStatus === 'pending' &&
          profile.data.role === 'systemowner' && (
            <FlexColumn
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10rem',
              }}
            >
              <TextH2 style={{ marginBottom: '1rem' }}>
                Pending to Approve
              </TextH2>
            </FlexColumn>
          )}
        {profile.islogin &&
          profile.data &&
          profile.data.kycStatus === 'rejected' && (
            <FlexColumn
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10rem',
              }}
            >
              <TextH2 style={{ marginBottom: '1rem' }}>Rejected</TextH2>
            </FlexColumn>
          )}
      </div>
      <SideNavbar />
    </LayoutWrapper>
  );
}

export default Layout;
