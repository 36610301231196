import colors from 'colors/colors';
import { BsMoonFill, BsFillSunFill } from 'react-icons/bs';
import { useAppDispatch } from 'state';
import { useThemeType } from 'state/theme/hooks';
import { TOGGLETHEME } from 'state/theme/reducer';
import styled from 'styled-components';
import { flexAlign } from 'styling/fast';
import { CSSTransition } from 'react-transition-group';
import { SimpleComponent } from 'interface';

const ToggleThemeBtnWrapper = styled.div<{ light: boolean }>`
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  color: ${colors.yellow.primary};
  font-size: 2rem;
  display: flex;
  ${flexAlign}
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${({ light }) => (!light ? colors.grey[9] : 'white')};
  }
  .ico {
    position: absolute;
  }
`;

export default function ToggleThemeBtn(props: SimpleComponent) {
  const dispatch = useAppDispatch();
  const theme = useThemeType();

  const toggle = () => {
    dispatch(TOGGLETHEME());
  };

  return (
    <ToggleThemeBtnWrapper
      style={props.style}
      className={props.className || ''}
      onClick={toggle}
      light={theme === 'light'}
    >
      <CSSTransition
        unmountOnExit
        mountOnEnter
        in={theme === 'light'}
        classNames="fade"
        timeout={200}
      >
        <BsMoonFill className="ico" />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        mountOnEnter
        in={theme === 'dark'}
        classNames="fade"
        timeout={200}
      >
        <BsFillSunFill className="ico" />
      </CSSTransition>
    </ToggleThemeBtnWrapper>
  );
}
