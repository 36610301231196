import {
  FlexColumn,
  FlexRow,
  GridColumn,
  Text,
  TextH1,
  TextHover,
} from 'components/box';
import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'components/Image';
import { Form } from 'antd';
import InputPassword from 'components/Input/Password';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Input from 'components/Input';
import Button from 'components/Button';
import { device } from 'styling/fast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CheckboxStyled from 'components/Input/Checkbox';
import usePopup from 'hooks/usePopup';
import useConfirm from 'hooks/useConfirm';
import { apiPublic } from 'Api';
import useQuery from 'hooks/useQuery';

const md5 = require('md5');

const LoginWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .login_container {
    width: 50rem;
  }
  .logo {
    width: 10rem;
    height: 10rem;
  }
  .form_item {
    input {
      font-size: 2rem;
    }
  }
  @media ${device.laptop} {
    .login_container {
      width: 90%;
    }
    .form_item {
      input {
        font-size: 2.5rem;
      }
    }
  }
  .back_btn {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 3rem;
  }
`;

function ResetPassword() {
  const [form] = Form.useForm<LoginForm>();
  const { isConfirmed } = useConfirm();
  const popUp = usePopup();
  const query = useQuery();
  const naviagate = useNavigate();
  const [loading, setLoading] = useState(false);

  const token = query.get('token');

  const onFinish = async (values: any) => {
    setLoading(true);
    const con1 = await isConfirmed({ text: 'Confirm reset password' });
    if (con1.confirm) {
      const result = await apiPublic.auth.resetPassword(token, {
        password: md5(values.password),
      });
      if (result.success) {
        await isConfirmed({
          text: 'Confirm reset password',
          noCancel: true,
        });
        naviagate('/');
      }
    }
    setLoading(false);
  };

  return (
    <LoginWrapper>
      <Link to="/">
        <TextHover className="back_btn" text="BACK" />
      </Link>
      <GridColumn className="login_container">
        <FlexRow>
          <Image
            className="logo bounce"
            src="/image/logo/logo.svg"
            alt="climate-positive-logo"
          />
        </FlexRow>
        <TextH1 style={{ textAlign: 'center', margin: '1rem 0' }}>
          Reset Password
        </TextH1>
        <Form
          onFinish={onFinish}
          autoComplete="on"
          form={form}
          layout={'vertical'}
        >
          <Form.Item
            className="form_item"
            label={<Text>Password</Text>}
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                pattern: new RegExp(
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})'
                ),
                message:
                  'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number and one special character (!@#$%^&*.)',
              },
            ]}
          >
            <InputPassword
              size={'middle'}
              iconRender={(visible) =>
                visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
            />
          </Form.Item>
          <Form.Item
            className="form_item"
            label={<Text>Confirm Password</Text>}
            name="confirmpassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered does not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <InputPassword
              size={'middle'}
              iconRender={(visible) =>
                visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
            />
          </Form.Item>

          <Form.Item className="form_item">
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              type="submit"
              loading={loading}
              text="RESET PASSWORD"
            />
          </Form.Item>
        </Form>
      </GridColumn>
    </LoginWrapper>
  );
}

export default ResetPassword;
