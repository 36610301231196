import { Form, Select } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { FlexColumn, Text, TextH1 } from 'components/box';
import Button from 'components/Button';
import Input from 'components/Input';
import InputNumberStyled from 'components/Input/InputNumberStyled';
import Selectoption from 'components/Selectoption';
import useApi from 'hooks/useApi';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from 'styling/fast';

const Option = Select.Option;

const SetttingConstantWrapper = styled.div`
  .form_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .btn_submit {
    grid-column: 1 / -1;
  }
  @media ${device.tablet} {
    .flex_wrapper {
      width: 40rem;
      height: 80vh;
      overflow-y: scroll;
    }
    .form_container {
      display: block;
    }
  }
`;

function SetttingConstant({ onClose }) {
  const api = useApi();
  const [initial, setInital] = useState(null);
  const [form] = Form.useForm<dataForm>();
  const popUp = usePopup();
  const { isConfirmed } = useConfirm();

  const fetchConstant = async () => {
    const result = await api.device.getConstant();
    if (result.success && result.data) {
      setInital(result.data);
      console.log(initial);
    }
  };

  useEffect(() => {
    fetchConstant();
  }, []);

  const onFinish = async (value) => {
    const confirm = await isConfirmed({
      title: 'Setting Constant',
      text: 'Confirm setting constant',
    });
    if (confirm.confirm) {
      const result = await api.device.setContants(value);
      if (result.success && result.data) {
        popUp.success({
          title: 'Setting Constant',
          text: 'Setting Constant Success!',
        });
        onClose();
      } else {
        popUp.error({
          title: 'Setting Constant',
          text: 'Setting Constant Failed!',
        });
      }
    }
  };

  const modeSelect = ['IREC', 'CC'];

  return (
    <SetttingConstantWrapper>
      <FlexColumn className="flex_wrapper" style={{ padding: '2rem' }}>
        {initial && (
          <Form
            initialValues={initial}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            layout={'vertical'}
            className="form_container"
          >
            <Form.Item
              className="form_item"
              label={<Text>Conversion rate from MWh to Carbon Credit</Text>}
              name="MWh_CC"
              rules={[
                {
                  required: true,
                  message:
                    'Please input Conversion rate from MWh to Carbon Credit',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={100} />
            </Form.Item>

            <Form.Item
              className="form_item"
              label={<Text>Conversion rate from MWh to IREC</Text>}
              name="MWh_IREC"
              rules={[
                {
                  required: true,
                  message: 'Please input Conversion rate from MWh to IREC',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={100} />
            </Form.Item>

            <Form.Item
              className="form_item"
              label={<Text>Value of Carbon Credit to USD</Text>}
              name="CC_USD"
              rules={[
                {
                  required: true,
                  message: 'Please input Value of Carbon Credit to USD',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={100} />
            </Form.Item>

            <Form.Item
              className="form_item"
              label={<Text>Value of IREC to USD</Text>}
              name="IREC_USD"
              rules={[
                {
                  required: true,
                  message: 'Please input Value of IREC to USD',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={100} />
            </Form.Item>

            <Form.Item
              className="form_item"
              label={<Text>Target global/location radiation (hour/day)</Text>}
              name="RAD_HOUR"
              rules={[
                {
                  required: true,
                  message:
                    'Please input Target global/location radiation (hour/day)',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={100} />
            </Form.Item>

            <Form.Item
              className="form_item"
              label={<Text>Value of USD to DiM</Text>}
              name="DiM_USD"
              rules={[
                {
                  required: true,
                  message: 'Please input Value of USD to DiM',
                },
              ]}
            >
              <InputNumberStyled size={'middle'} min={0} max={18} />
            </Form.Item>

            <Selectoption>
              <Form.Item
                label={<Text> Mode </Text>}
                name="IRECMode"
                rules={[
                  {
                    required: true,
                    message: 'Please select Mode!',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  showSearch={true}
                  placeholder="Select mode"
                >
                  <Option value={0}>CC</Option>

                  <Option value={1}>IREC</Option>
                </Select>
              </Form.Item>
            </Selectoption>

            <Form.Item noStyle>
              <Button
                className="btn_submit"
                disabled={false}
                type={'submit'}
                style={{ width: '100%' }}
                text="Submit"
              />
            </Form.Item>
          </Form>
        )}
      </FlexColumn>
    </SetttingConstantWrapper>
  );
}

export default SetttingConstant;
