import { Form } from 'antd';
import axios from 'axios';
import { GridColumn, TextH2, Text, FlexColumn, FlexRow } from 'components/box';
import Button from 'components/Button';
import Image from 'components/Image';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Step2Wrapper } from './styled';

function KycStep6(props: PropsStep) {
  const theme = useTheme();
  return (
    <Step2Wrapper style={{ margin: '2rem 0' }}>
      <GridColumn gap="1rem">
        <FlexColumn>
          <TextH2 style={{ margin: '1rem 0' }} align="center">
            KYC Error
          </TextH2>
          <Text size="base" align="center">
            {props.error}
          </Text>
        </FlexColumn>
        <GridColumn gap="1rem">
          <FlexRow>
            <Image width={'20rem'} src="/image/kyc/complete.svg" />
          </FlexRow>
        </GridColumn>
        {/* <Button
          disabled={false}
          style={{
            width: '20rem',
            fontSize: '1.4rem',
            padding: '1rem 0',
            borderRadius: '2rem',
            margin: '0 auto',
          }}
          color={theme.graph1_2}
          text="Waiting for approval"
        /> */}
        <FlexRow>
          <Button
            tone="blue"
            disabled={false}
            style={{
              width: '20rem',
              fontSize: '1.4rem',
              padding: '1rem 0',
              borderRadius: '2rem',
            }}
            text="Back"
            onClick={() => {
              props.setCurrent(4);
            }}
          />
        </FlexRow>
      </GridColumn>
    </Step2Wrapper>
  );
}

export default KycStep6;
