import { createSlice } from '@reduxjs/toolkit';
import { apiServer } from 'Api';
import { ProfileStae } from 'state/types';

const initialState: ProfileStae = {
  account: '',
  token: '',
  islogin: false,
  isAdmin: 0,
  data: null,

  profilePicture: '',

  selectedSiteId: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    LOGIN: (state, action) => {
      window.localStorage.setItem('TOKEN', action.payload.token);
      state.token = action.payload.token;
      state.isAdmin = action.payload.isAdmin;
      state.profilePicture = action.payload.data?.kycData?.profilePicture ?? '';
      state.data = action.payload.data;
      state.islogin = true;
      state.data = action.payload.data || null;
    },
    LOGOUT: (state) => {
      window.localStorage.removeItem('TOKEN');
      state.token = '';
      state.isAdmin = 0;
      state.islogin = false;
      state.profilePicture = '';
      state.data = null;
      state.selectedSiteId = '';
    },
    SETSITEID: (state, action) => {
      state.selectedSiteId = action.payload;
    },
  },
});
export default profileSlice.reducer;
