import { Form } from 'antd';
import axios from 'axios';
import { GridColumn, TextH2, Text, TextHover, FlexRow } from 'components/box';
import Button from 'components/Button';
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton';
import Input from 'components/Input';
import useApi from 'hooks/useApi';
import usePopup from 'hooks/usePopup';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { isAddress } from 'utils';
import { getWalletAddress } from 'utils/token';
import { Step2Wrapper } from './styled';

const md5 = require('md5');

function KycStep4(props: PropsStep) {
  const popup = usePopup();
  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [needChooseWallet, setNeedChooseWallet] = useState(false);
  const [token, setToken] = useState('');
  const apiServer = useApi();

  async function fetchWalletAddress() {
    let w = await getWalletAddress();
    if (w) {
      setWalletAddress(w);
      setNeedChooseWallet(true);
    }
  }

  const onSubmit = async (values: any) => {
    setLoading(true);
    let data = {
      ...props.data,
      ...values,
      walletAddress,
    };
    props.setData(data);

    const response = await apiServer.kyc.submit({
      ...data,
      password: md5(data.password),
    }, token);
    setLoading(false);

    if (response.success && response.data) {
      // popup.success({
      //   text: 'Submit KYC success',
      //   callBack: () => {
      //     if (props.success === 3) {
      //       props.setSuccess(4);
      //     }
      //     props.setCurrent(5);
      //   },
      // });

      props.setCurrent(5);
    } else {
      props.setError(response.message);
      props.setCurrent(6);
      // popup.error({ text: 'Submit KYC error: ' + response.message });
    }
  };

  const loginCallback = ({ token }, walletAddress) => {
    setWalletAddress(walletAddress);
    setToken(token);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (e) => {
    props.onChangeData(e);
  };

  useEffect(() => {
    fetchWalletAddress();
  }, []);

  const theme = useTheme();

  return (
    <Step2Wrapper style={{ margin: '4rem 0' }}>
      <Form
        initialValues={props.data}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
        form={props.form}
        layout={'vertical'}
      >
        <TextH2 align="center">Insert Your Wallet Information</TextH2>

        <GridColumn gap="1rem" className="container_form">
          <Input placeholder="Connect wallet address" value={walletAddress} />
          <div className="connect_wallet_btn">
            <ConnectWalletButton
              loginOnly={true}
              onLogin={loginCallback}
              style={{ borderRadius: '1rem', backgroundColor: theme.blue }}
            ></ConnectWalletButton>
          </div>

          {/* <Form.Item
            className="form_item"
            label={<Text>Wallet Address (BSC)</Text>}
            name="walletAddress"
            rules={[
              {
                validator: async (_, walletAddress) => {
                  if (!isAddress(walletAddress)) {
                    return Promise.reject(
                      new Error('Please input your Wallet address!')
                    );
                  }
                },
              },
            ]}
            hidden={true}
          >
            <Input size={'middle'} type={'text'} />
          </Form.Item> */}

          {/* <a href="/" target={'_blank'}>
            <TextHover
              style={{ textAlign: 'right' }}
              size="base"
              text="Wallet creation guide"
            />
          </a> */}

          <Form.Item className="form_item" noStyle>
            <Button
              type="submit"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={loading}
              text="Submit"
              hidden={!walletAddress}
            />
          </Form.Item>
        </GridColumn>
      </Form>
    </Step2Wrapper>
  );
}

export default KycStep4;
