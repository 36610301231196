import { Select, Form } from 'antd';
import {
  GridColumn,
  TextH2,
  Text,
  TextHover,
  FlexColumn,
  FlexRow,
} from 'components/box';
import Button from 'components/Button';
import ImagePicker from 'components/ImagePicker';
import Input from 'components/Input';
import CheckboxStyled from 'components/Input/Checkbox';
import InputPassword from 'components/Input/Password';
import Modal from 'components/ModalPopup/Modal';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import Selectoption from 'components/Selectoption';
import TermAndCondition from 'feature/term';
import useCountry from 'hooks/useCountry';
import usePopup from 'hooks/usePopup';
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Step2Wrapper } from './styled';
import Image from 'components/Image';

const { Option } = Select;

function KycStep2(props: PropsStep) {
  const country = useCountry();
  const popup = usePopup();
  const [term, setTerm] = useState(false);
  const [profilePicture, setProfilePicture] = useState(props.data.profilePicture);

  const onFinish = (values: any) => {
    if (!profilePicture) {
      popup.error({ text: 'Please upload your profile picture' });
      return;
    }

    props.setData({ ...props.data, ...values, profilePicture });
    if (props.success === 1) {
      props.setSuccess(2);
    }
    if (props.data.role === 'tokenholder') {
      props.setCurrent(4);
    } else {
      props.setCurrent(3);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (e) => {
    props.onChangeData(e);
  };

  return (
    <Step2Wrapper style={{ margin: '4rem 0' }}>
      <Form
        initialValues={props.data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
        form={props.form}
        layout={'vertical'}
      >
        <TextH2 align="center">Enter Your Information</TextH2>
        <GridColumn gap="0" className="container_form">
          <Form.Item
            className="form_item"
            label={<Text>Name / Corporate Name (Full Name)</Text>}
            name="name"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please input your Name!',
              },
            ]}
          >
            <Input size={'middle'} type={'text'} disabled={props.isAutofill} />
          </Form.Item>
          <Form.Item
            className="form_item"
            label={<Text> Email address</Text>}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              autoComplete="off"
              size={'middle'}
              type={'email'}
              disabled={props.isAutofill}
            />
          </Form.Item>
          <Selectoption>
            <Form.Item
              className="form_item"
              label={<Text> Country of Residence</Text>}
              name="country"
              rules={[
                {
                  required: true,
                  message: 'Please input your country!',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                showSearch={true}
                placeholder="Select Country of residence"
                disabled={props.isAutofill}
              >
                {country.map((e, i) => (
                  <Option key={'country-' + e.name} value={JSON.stringify(e)}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Selectoption>

          <Form.Item
            className="form_item"
            label={<Text>Username</Text>}
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input size={'middle'} type={'text'} disabled={props.isAutofill} />
          </Form.Item>

          <Form.Item
            className="form_item"
            label={<Text>Password</Text>}
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                pattern: new RegExp(
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})'
                ),
                message:
                  'Minimum eight characters, at least one uppercase letter, one lowercase letter and one number and one special character (!@#$%^&*.)',
              },
            ]}
          >
            <InputPassword
              size={'middle'}
              iconRender={(visible) =>
                visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
            />
          </Form.Item>
          <Form.Item
            className="form_item"
            label={<Text>Confirm Password</Text>}
            name="confirmpassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered does not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <InputPassword
              size={'middle'}
              iconRender={(visible) =>
                visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
            />
          </Form.Item>

          <GridColumn gap="0.5rem">
            <Text>Profile picture</Text>
            {props.isAutofill ? 
              <Image src={profilePicture} width={100} height={100} style={{marginTop: '0.5rem', marginBottom: '1rem'}}></Image> :
              <ImagePicker onChange={(x) => setProfilePicture(x)} single={true}></ImagePicker>
            }
          </GridColumn>

          <Form.Item
            className="form_item"
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('You need to agree to continue.')
                      ),
              },
            ]}
          >
            <CheckboxStyled
              label={
                <FlexRow>
                  <Text style={{ textAlign: 'left' }} size="base">
                    I have read and agree to the
                  </Text>
                  <TextHover
                    onClick={() => {
                      setTerm(true);
                    }}
                    style={{
                      textAlign: 'left',
                      fontWeight: 500,
                      marginLeft: '0.3rem',
                    }}
                    size="base"
                    text="Terms and Conditions"
                  />
                </FlexRow>
              }
            />
          </Form.Item>

          <Form.Item className="form_item">
            <Button
              style={{ width: '100%', marginTop: '1rem' }}
              type="submit"
              disabled={false}
              text="Next"
            />
          </Form.Item>
        </GridColumn>
      </Form>

      <ModalPopup
        title={'Term & Conditions'}
        show={term}
        blockStyle={{ padding: 0 }}
        onClose={() => {
          setTerm(false);
        }}
      >
        <TermAndCondition />
      </ModalPopup>
    </Step2Wrapper>
  );
}

export default KycStep2;
