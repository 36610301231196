import { FlexRow, GridColumn, Text, TextB, TextHover } from 'components/box';
import UserBadge from 'components/UserBadge';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { numberWithCommas } from 'utils/changeNumberofData';

function PopularState({ MWh_CC = 0, leaderboard }) {
  const theme = useTheme();
  const data = leaderboard;

  return (
    <GridColumn className="box" gap="1rem">
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <TextB>Top System Size</TextB>
      </FlexRow>
      <GridColumn gap="1rem">
        {data.map(row =>
          <FlexRow style={{ justifyContent: 'space-between' }} key={row.siteId}>
            <FlexRow>
              <UserBadge
                style={{
                  width: '4rem',
                  height: '4rem',
                  marginRight: '1rem',
                  padding: '0.1rem',
                  backgroundColor: theme.gray_4,
                  fontSize: '2rem',
                }}
                src={row.kycData?.profilePicture}
              />
              <GridColumn gap="0.2rem">
                <TextB>{row.username} ({numberWithCommas(parseFloat(row.systemSize).toFixed(2))} kW)</TextB>
                {MWh_CC > 0 && <Text>Carbon Credit: {numberWithCommas(Math.ceil(row.totalWh / 1000000 * MWh_CC))} DiM</Text>}
              </GridColumn>
            </FlexRow>
            {/* <AiFillPlusCircle size={30} color={theme.textp} /> */}
          </FlexRow>
        )}
      </GridColumn>
    </GridColumn>
  );
}

export default PopularState;
