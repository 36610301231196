import { Input, InputNumber, InputNumberProps } from 'antd';
import { SimpleComponent } from 'interface';
import React from 'react';
import { InputProps } from 'antd/lib/input/Input';
import styled from 'styled-components';

interface Props extends SimpleComponent, InputNumberProps {}

export const InputWrapper = styled.div`
  .ant-input-number {
    width: 100%;
    background-color: transparent;
    border-radius: 0.5rem !important;
    outline: 0;
    border: 0;
  }

  .ant-input-number:hover {
    background-color: transparent;
  }

  .ant-input-number .ant-input-number-input-wrap input {
    height: auto;
    padding: 0.8rem 1rem !important;
    font-size: 1.4rem !important;
    border-radius: 0.5rem !important;
    outline: 0 !important;
    background-color: ${({ theme }) => theme.box} !important;
    color: ${({ theme }) => theme.gray_8} !important;
    border: 1px solid;
    border-color: ${({ theme }) => theme.gray_2};
  }

  .ant-input-number .ant-input-number-input-wrap input:hover {
    border-color: ${({ theme }) => theme.main};
  }

  .ant-input-number .ant-input-number-input-wrap input:focus {
    border-color: ${({ theme }) => theme.main};
  }
`;

function InputNumberStyled(props: Props) {
  return (
    <InputWrapper className={props.className} style={props.style}>
      <InputNumber controls={false} {...props} style={null} className="" />
    </InputWrapper>
  );
}

export default InputNumberStyled;
