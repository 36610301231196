import PopupContext from 'context/Popup';
import React from 'react';
import { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { CgTimer } from 'react-icons/cg';
import { AiOutlineClose, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { toneBg, toneColor } from 'styling/fast';
import { FlexRow } from 'components/box';
import colors from 'colors/colors';
import { BsCheckCircleFill } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import Button from 'components/Button';

const StyledModalContainer = styled.div<{ tone: string }>`
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .ModalContent {
    position: relative;
  }
  .bgDark_modal {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.5;
    transition: all 0.5s;
  }
  .container_modal {
    position: relative;
    height: auto;
    width: auto;
    border-radius: 2rem;
    background: ${({ theme }) => theme.bg};
    /* padding: 2rem 0; */
  }
  .exit_btn {
    font-size: 20px;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    cursor: pointer;
    padding: 0.8rem;
    border-radius: 0.8rem;
    transition: 0.5s all;
    color: white;
    :hover {
    }
  }
  .title_text {
    font-size: 22px;
    color: white;
    font-weight: 500;
    padding: 2rem 3rem;
    padding-right: 10rem;
    background: ${({ theme }) => theme.main};
    border-radius: 1.5rem 1.5rem 0 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .line_modal {
    width: 100%;
    height: 3px;
    margin-bottom: 1rem;
  }
  .content_modal {
    min-width: 50rem;
    border-radius: 0 0 2rem 2rem;
    border-top: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text_content {
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    color: ${({ theme }) => theme.textp};
  }
  .icon {
    font-size: 5rem;
  }
  .btn {
    margin-top: 2rem;
  }
`;

const PopupBar = () => {
  const popupCtx = useContext(PopupContext);

  const theme = useTheme();

  const closeModal = () => {
    popupCtx.clear();
    if (popupCtx.content.callBack) {
      popupCtx.content.callBack();
    }
  };

  const ico = {
    loading: (
      <AiOutlineLoading3Quarters color={theme.main} className="icon spining" />
    ),
    success: (
      <BsCheckCircleFill color={colors.green.primary} className="icon" />
    ),
    error: <BiError color={theme.red_2} className="icon" />,
  };

  return (
    <React.Fragment>
      <CSSTransition
        in={Boolean(popupCtx.popup)}
        timeout={200}
        classNames="fade"
        unmountOnExit
        mountOnEnter
      >
        <StyledModalContainer tone={popupCtx.tone}>
          <div className="bgDark_modal" onClick={closeModal} />
          <div className={`container_modal`}>
            <div onClick={closeModal} className="exit_btn">
              <AiOutlineClose />
            </div>
            {popupCtx.content.title && (
              <h1 className="title_text">{popupCtx.content.title}</h1>
            )}
            <div className="content_modal">
              {ico[popupCtx.content.type]}
              <FlexRow className="text_content">
                {popupCtx.content.text}
              </FlexRow>
              <Button
                onClick={closeModal}
                disabled={false}
                text="Confirm"
                className="btn"
              />
            </div>
          </div>
        </StyledModalContainer>
      </CSSTransition>
    </React.Fragment>
  );
};
export default PopupBar;
