import { Select } from "antd";
import colors from "colors/colors";
import {
  FlexColumn,
  FlexRow,
  GridColumn,
  GridRow,
  Text,
  TextH2,
} from "components/box";
import { ButtonActive } from "components/ButtonActive/ButtonActive";
import Selectoption from "components/Selectoption";
import React from "react";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "state";
import { SETSITEID } from "state/profile/action";
import styled from "styled-components";
import { device } from "styling/fast";

const { Option } = Select;

export const MainSelectWrapper = styled.div`
  .button_select {
    margin: 0 0.2rem;
  }
  @media ${device.tablet} {
    .container {
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr;
    }
  }
`;

function MainSelect({ capacity, type, select, setSelect, devices, location }) {
  const devicesParse = devices.map((e) => e.siteId);
  const siteId = useSelector((state: AppState) => state.profile.selectedSiteId);
  const dispatch = useAppDispatch();
  const onChangeDevice = (e) => {
    dispatch(SETSITEID(e));
  };
  return (
    <MainSelectWrapper>
      <FlexRow
        className="container"
        style={{ justifyContent: "space-between" }}
      >
        <GridColumn gap="1rem">
          <Text>
            System Location: {location.address} {location.city} {location.zip},{" "}
            {location.country}
          </Text>
          <TextH2 size="xl" weight="700">
            Capacity: {capacity} kW
          </TextH2>
        </GridColumn>
        <GridColumn gap="1rem">
          <FlexRow>
            {type.map((e) => (
              <ButtonActive
                className="button_select"
                key={e}
                active={e === select}
                onClick={() => {
                  setSelect(e);
                }}
              >
                {e.toUpperCase()}
              </ButtonActive>
            ))}
          </FlexRow>
          <FlexRow className="siteSelect">
            <Text style={{ marginRight: "1rem" }}>Site Id</Text>
            <Selectoption style={{ width: "100%" }}>
              <Select
                fieldNames={{ label: "siteId" }}
                style={{ width: "20rem" }}
                value={siteId}
                onChange={onChangeDevice}
              >
                {devicesParse.map((e, i) => (
                  <Option key={"devices-" + e} value={e}>
                    {e}
                  </Option>
                ))}
              </Select>
            </Selectoption>
          </FlexRow>
        </GridColumn>
      </FlexRow>
    </MainSelectWrapper>
  );
}

export default MainSelect;
