import styled from 'styled-components';
import { device } from 'styling/fast';

export const AdminUserWrapper = styled.div`
  .action_container {
    justify-content: space-between;
  }
  .search {
    position: relative;
    .icon,
    input {
      font-size: 1.4rem;
    }
  }
  .user_container {
    min-height: 80vh;
    gap: 1rem;
    margin-top: 2rem;
  }
`;

export const UserItemWrapper = styled.div`
  width: 100%;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.box};
  border-radius: 1rem;
  border: 0.05px solid ${({ theme }) => theme.border};
  position: relative;
  display: flex;
  align-items: center;
  .container {
    width: 100%;
    justify-content: space-between;
  }
  .user_img {
    width: 7rem;
    height: 7rem;
    padding: 0.1rem;
    font-size: 5rem;
    margin-right: 2rem;
    .user_img_inside {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  .action_contain {
    height: 100%;
    width: 60%;
    place-items: flex-end;
    align-items: center;
    gap: 2rem;
    grid-template-columns: 1fr 24rem;
    position: relative;
  }
  .menu_action {
    justify-content: flex-end;
  }
  .action_last {
    width: 100%;
    justify-content: space-between;
    @media ${device.laptop} {
      justify-content: center;
    }
  }
  .menu_action_items {
    align-items: center;
    height: 6rem;
    width: 6rem;
    margin: 0 0.5rem;
    background-color: ${({ theme }) => theme.bg};
    .icon {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    font-size: 2rem;
    cursor: pointer;
    padding: 1rem;
    border-radius: 1rem;
    :hover {
      background-color: ${({ theme }) => theme.gray_2};
      .icon {
        svg {
          color: ${({ theme }) => theme.gray_8};
        }
      }
    }
  }
  .detail {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0;
    .detail_item {
      place-items: center;
      position: relative;
    }
    .detail_item::after {
      content: '';
      width: 0.1rem;
      height: 60%;
      position: absolute;
      right: 0;
      background-color: ${({ theme }) => theme.separator};
    }
    .detail_item:last-child::after {
      height: 0;
    }
  }

  .dropdown_btn {
    position: absolute;
    right: 0;
  }
  .vertical_line {
    width: 0.2rem;
    height: 3rem;
    background-color: ${({ theme }) => theme.gray_8};
  }
  @media ${device.laptop} {
    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .action_contain {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      place-items: center;
    }
    .dropdown_btn {
      top: 1rem;
      right: 1rem;
    }
  }
`;
