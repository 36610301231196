import { Form, Input } from 'antd';
import { FlexRow, GridColumn, Text } from 'components/box';
import { InputWrapper } from 'components/Input';
import LayoutContent from 'components/Layout/LayoutContent';
import React, { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { AdminUserWrapper } from './styled';
import { ButtonActive } from 'components/ButtonActive/ButtonActive';
import UserItem from './UserItem';
import PaginationStyled from 'components/PaginationStyled';
import useApi from 'hooks/useApi';

function AdminUser() {
  const [form] = Form.useForm();
  const [approved, setApproved] = useState('');
  const [search, setSearch] = useState('');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const row = 10;
  const table = useRef(null);
  const [loading, setLoad] = useState(false);
  const apiServer = useApi();

  const onFinish = (value) => {
    console.log(value);
    setSearch(value.search);
  };

  const fetchData = async () => {
    const result = await apiServer.device.get(
      approved,
      row,
      (page - 1) * row,
      search
    );
    if (result.success) {
      setData(result.data.data);
      setTotal(result.data.total);
    }
  };

  const onPageChange = (e) => {
    setPage(e);
  };

  useEffect(() => {
    fetchData();
  }, [page, loading, search, approved]);

  const setLoading = () => {
    setPage(1);
    setLoad(!loading);
  };

  const toggle = (e) => {
    if (e !== approved) {
      setApproved(e);
    } else {
      setApproved('');
    }
  };

  return (
    <LayoutContent title="Admin Dashboard">
      <AdminUserWrapper>
        <FlexRow className="action_container">
          <FlexRow className="search">
            <Form form={form} name="control-hooks" onFinish={onFinish}>
              <InputWrapper>
                <Form.Item noStyle name="search" rules={[{ required: false }]}>
                  <Input
                    autoFocus
                    prefix={<BiSearch className="icon" />}
                    placeholder="Search"
                  />
                </Form.Item>
              </InputWrapper>
            </Form>
            <Text style={{ marginLeft: '1rem' }}>{data.length} results</Text>
          </FlexRow>

          <FlexRow>
            <ButtonActive
              active={approved === 'approved'}
              onClick={() => toggle('approved')}
              style={{
                padding: '1rem 1rem',
                width: 'auto',
                marginLeft: '0.5rem',
              }}
            >
              Approved
            </ButtonActive>

            <ButtonActive
              active={approved === 'rejected'}
              onClick={() => toggle('rejected')}
              style={{
                padding: '1rem 1rem',
                width: 'auto',
                marginLeft: '0.5rem',
              }}
            >
              Rejected
            </ButtonActive>

            <ButtonActive
              active={approved === 'pending'}
              onClick={() => toggle('pending')}
              style={{
                padding: '1rem 1rem',
                width: 'auto',
                marginLeft: '0.5rem',
              }}
            >
              Pending
            </ButtonActive>
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ justifyContent: 'flex-end', margin: '1rem' }}>
          <PaginationStyled
            total={total}
            onChange={onPageChange}
            row={row}
            page={page}
          />
        </FlexRow>
        <GridColumn className="user_container">
          {data.map((e, i) => (
            <UserItem
              key={'user' + i}
              data={e}
              color={'green'}
              setLoading={setLoading}
            />
          ))}
        </GridColumn>
      </AdminUserWrapper>
    </LayoutContent>
  );
}

export default AdminUser;
