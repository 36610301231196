import { combineReducers } from '@reduxjs/toolkit';
import profileReducer from './profile/reducer';
import loadingReducer from './loading/reducer';
import blockReducer from './block/reducer';
import multicallReducer from './multicall/reducer';
import themeReducer from './theme/reducer';
import layoutSlide from './layout/reducer';

export default combineReducers({
  profile: profileReducer,
  refreshing: loadingReducer,
  block: blockReducer,
  multicall: multicallReducer,
  theme: themeReducer,
  layout: layoutSlide,
});
