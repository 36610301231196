import Endpoint from '../Endpoint';

export default class DevicesEndpointed extends Endpoint {
  async get(kycStatus, limit, skip = 0, search) {
    console.log(kycStatus);
    return await this.axiosWrapper
      .get(`/listDevices`, {
        params: {
          kycStatus,
          limit,
          skip,
          search,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getPendingWithdrawAdmin(limit, skip = 0, search) {
    return await this.axiosWrapper
      .get(`/getPendingWithdrawAdmin`, {
        params: {
          limit,
          skip,
          search,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async delete(siteId) {
    return await this.axiosWrapper
      .post(`/deleteDevice`, siteId)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async approve(type, siteId) {
    return await this.axiosWrapper
      .post(`/approveKyc`, { approved: type, siteId })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async rejected(type, siteId, reason) {
    return await this.axiosWrapper
      .post(`/approveKyc`, { approved: type, siteId, reason })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getBySiteId(siteId) {
    return await this.axiosWrapper
      .get(`/getBySiteId`, { params: { siteId } })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getByWallet(walletAddress) {
    return await this.axiosWrapper
      .get(`/getByWallet`, { params: { walletAddress } })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async setContants(value) {
    return await this.axiosWrapper
      .post(`/updateMulConst`, value)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getConstant() {
    return await this.axiosWrapper
      .get(`/getMulConst`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getChart(siteId, timeUnit, startTime, endTime) {
    return await this.axiosWrapper
      .get(`/chart`, { params: { siteId, timeUnit, startTime, endTime } })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getLeaderboard() {
    return await this.axiosWrapper
      .get(`/getLeaderboard`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
