import colors from 'colors/colors';
import styled from 'styled-components/macro';
import { device } from 'styling/fast';

export const KycWrapper = styled.div`
  .nav {
    width: 100%;
    height: 7rem;
    justify-content: space-between;
    padding: 0 2rem;
    background-color: ${(props) => props.theme.nav};
    transition: all 0.2s ease-in-out;
  }
  .logo_contain {
    cursor: pointer;
    padding: 0.1rem;
    padding-right: 1rem;
    height: 5rem;
    width: 5rem;
    border-right: 2px ${colors.grey[5]} solid;
    margin-right: 1rem;
  }
  .control {
    align-items: center;
    font-size: 3rem;
    color: ${(props) => props.theme.gray_4};
    margin: 0 1rem;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.main};
    }
  }
`;

export const LoadingPercent = styled.div<{ percent: number }>`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.minimal};
  div {
    height: 100%;
    width: ${({ percent }) => percent + '%'};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.main};
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
  }
`;

export const KycTypeWrapper = styled.div<{ active: boolean }>`
  width: 30rem;
  height: 50rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 2rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.box : 'transparent'};
  :hover {
    background-color: ${({ theme }) => theme.box};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  .img_role {
    width: 100%;
    height: 30rem;
    margin-bottom: 3rem;
    img {
      border-radius: 1rem;

      object-fit: cover;
    }
  }
  .btn {
    margin-top: 3rem;
    font-size: 1.4rem;
  }
`;

export const Step1Wrapper = styled.div`
  .container {
    height: 100%;
    padding: 4rem;
  }
  .type_container {
    margin: 3rem auto;
    margin-top: 2rem;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

export const Step2Wrapper = styled.div`
  min-height: 100vh;
  .connected_to {
    justify-content: center;
    font-size: 1.5rem;
    margin-top: 12px;
    text-align: center;
  }
  .connect_wallet_btn .login_btn {
    width: 100%;
  }
  .container {
    height: 100%;
    padding: 4rem;
  }
  .container_form {
    width: 50rem;
    padding: 2rem;
    border-radius: 2rem;
    margin: 1rem auto;
    /* background-color: ${({ theme }) => theme.nav}; */
    /* border: 1px solid ${({ theme }) => theme.minimal}; */
  }
  .select_location {
    background-color: ${({ theme }) => theme.gray_2};
  }
  .ant-form .ant-form-item .ant-form-item-label {
    flex: none;
  }
  .form_item {
    flex-wrap: nowrap;
  }
  @media ${device.mobileL} {
    .container_form {
      width: 40rem;
    }
  }
`;
