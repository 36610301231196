import { DatePicker, Form, Select } from 'antd';
import { GridColumn, TextH2, Text } from 'components/box';
import Button from 'components/Button';
import Googlemap from 'components/Googlemap';
import Input from 'components/Input';
import DatePickerWrapper from 'components/Input/DatePickerWrapper';
import Selectoption from 'components/Selectoption';
import { AiFillCalendar } from 'react-icons/ai';
import { Step2Wrapper } from './styled';
import { useState } from 'react';
import { disableScroll } from 'hooks/scrollBody';
import { enableScroll } from 'utils/scrollBody';
import ImagePicker from 'components/ImagePicker';
import usePopup from 'hooks/usePopup';
import useApi from 'hooks/useApi';

const Option = Select.Option;

function KycStep3(props: PropsStep) {
  const type = ['Self ownership', 'Corporate ownership', 'Financial ownership'];
  const [map, setMap] = useState(false);
  const [images, setImages] = useState([]);
  const popup = usePopup();
  const apiServer = useApi();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    console.log('test');
    console.log(values);

    let siteId = values.siteId;
    let apiKey = values.apiKey;

    // test apiKey
    setLoading(true);
    try {
      let response = await apiServer.kyc.fetchRawDevice({ siteId, apiKey });
      if (!response.data) {
        popup.error({ text: 'Invalid API Key or Site ID' });
        return;
      }
    } catch (err) {
      popup.error({ text: 'Invalid API Key or Site ID' });
      return;
    } finally {
      setLoading(false);
    }

    props.setData({ ...props.data, ...values, picture: images });
    if (props.success === 2) {
      props.setSuccess(3);
    }
    props.setCurrent(4);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (e) => {
    // props.onChangeData(e);
  };

  const showMap = () => {
    disableScroll();
    setMap(true);
  };

  const closeMap = () => {
    enableScroll();
    setMap(false);
  };

  return (
    <Step2Wrapper style={{ margin: '4rem 0' }}>
      <Form
        initialValues={props.data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off"
        form={props.form}
        layout={'vertical'}
      >
        <TextH2 align="center">Enter Your System Information</TextH2>
        <GridColumn gap="0" className="container_form">
          <Form.Item
            className="form_item"
            label={<Text>Project Code</Text>}
            name="projectCode"
            rules={[
              {
                required: true,
                message: 'Please input your project code!',
              },
            ]}
          >
            <Input size={'middle'} placeholder="STP000" />
          </Form.Item>

          <Form.Item
            className="form_item"
            label={<Text>Site ID</Text>}
            name="siteId"
            rules={[
              {
                required: true,
                message: 'Please input your site ID!',
              },
            ]}
          >
            <Input size={'middle'} />
          </Form.Item>

          <Form.Item
            className="form_item"
            label={<Text>API Key</Text>}
            name="apiKey"
            rules={[
              {
                required: true,
                message: 'Please input your API Key!',
              },
            ]}
          >
            <Input size={'middle'} />
          </Form.Item>

          <DatePickerWrapper>
            <Form.Item
              className="form_item"
              label={<Text>Operation Started (Month, Year)</Text>}
              name="installationDate"
              rules={[
                {
                  required: true,
                  type: 'date',
                  message: 'Please input your Start date!',
                },
              ]}
            >
              <DatePicker
                suffixIcon={<AiFillCalendar />}
                style={{ width: '100%' }}
                picker="month"
              />
            </Form.Item>
          </DatePickerWrapper>

          <Form.Item
            className="form_item"
            label={<Text>Installed Capacity</Text>}
            name="installedCapacity"
            rules={[
              {
                required: true,
                message: 'Please input your Installed capacity!',
              },
            ]}
          >
            <Input size={'middle'} type={'number'} suffix={'kW'} />
          </Form.Item>
          <Selectoption>
            <Form.Item
              className="form_item"
              label={<Text>Type of Ownership</Text>}
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select your type of ownership!',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                showSearch={false}
                placeholder="Select Type of ownership"
                optionFilterProp="children"
                dropdownClassName="dropdown"
                // bordered={false}
              >
                {type.map((e, i) => (
                  <Option key={`type-${i}`} value={e}>
                    {e}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Selectoption>

          <Form.Item
            className="form_item"
            label={<Text>Remaining ownership period</Text>}
            name="period"
            rules={[
              {
                required: true,
                message: 'Please input your Period!',
              },
            ]}
          >
            <Input size={'middle'} type={'number'} suffix={'Year'} />
          </Form.Item>

          <GridColumn gap="0.5rem">
            <Text>System overall picture</Text>
            <ImagePicker onChange={(x) => setImages(x)}></ImagePicker>
          </GridColumn>

          {/* <GridColumn gap="0.5rem">
            <Text>Location of the system</Text>
            <Button
              className="select_location"
              onClick={showMap}
              text="Select your location"
              disabled={false}
            />
            <CSSTransition
              unmountOnExit
              mountOnEnter
              in={map}
              classNames="fade"
              timeout={200}
            >
              <Googlemap closeMap={closeMap} />
            </CSSTransition>
          </GridColumn> */}
          <Form.Item className="form_item">
            <Button
              type="submit"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={loading}
              text="Next"
            />
          </Form.Item>
        </GridColumn>
      </Form>
    </Step2Wrapper>
  );
}

export default KycStep3;
