import { createGlobalStyle } from 'styled-components/macro';
import { purple, red } from '@ant-design/colors';

// font-family: 'Montserrat', sans-serif;
// font-family: 'Roboto', sans-serif;

const AntDConfig = createGlobalStyle`

  
/* antd */
/* calendar */
// AntD global
/* .subPopUp{
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: white;
    span{
      color: ${purple.primary};
    }
  }
} */
.ant-picker-panel-container {
    background-color: ${({ theme }) => theme.box};
}

.ant-picker-cell:hover .ant-picker-cell-inner{ 
  background-color: ${({ theme }) => theme.gray_4} !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background-color: ${({ theme }) => theme.gray_4};
}

.ant-picker-input > input{
  padding: 0.8rem 1rem;
  height:auto;
  color: ${({ theme }) => theme.gray_8};
}

.ant-form-item-has-error .ant-input-number:not([disabled]):hover, .ant-form-item-has-error .ant-picker:not([disabled]):hover{
  background-color: transparent;
}

.ant-form-item-has-error .ant-input-number .ant-input-number-input-wrap input{
  border-color: #820014;
}

/* input error */
.ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-color: ${({ theme }) => theme.red_2};
    background-color: ${({ theme }) => theme.box};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
    border-color: ${({ theme }) => theme.red_2};
    background-color: ${({ theme }) => theme.gray_2};
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    color:  ${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_2};
  }

  .ant-select-dropdown{
    background-color:  ${({ theme }) => theme.box};
  }

  &.ant-select-item{
    color:  ${({ theme }) => theme.gray_8};
  }

  &.ant-select-item:hover{
    color:  ${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_2};
  }

  &.ant-select-item:not(:hover){
    color:  ${({ theme }) => theme.gray_8};
  }

  &.ant-dropdown{
    border: 0.1rem solid ${({ theme }) => theme.border};
    background-color:  ${({ theme }) => theme.box};
  }

  &.ant-dropdown-menu{
    background-color:  ${({ theme }) => theme.box};
  }

  &.ant-dropdown-menu-item{
    color:  ${({ theme }) => theme.gray_8};
    background-color:  ${({ theme }) => theme.box};
  }

  &.ant-dropdown-menu-item:hover{
    color:  ${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_2};
  }

  /* .ant-select-dropdown{
  background-color: ${({ theme }) => theme.gray_2};
    *{
      color: ${({ theme }) => theme.textp};
  }
} */

  &.ant-select-item-option-selected{
    color:${({ theme }) => theme.gray_8} !important;
    background-color: ${({ theme }) => theme.gray_4} !important;
  }
  &.ant-select-item-option-selected:hover{
    color:${({ theme }) => theme.gray_8};
    background-color: ${({ theme }) => theme.gray_4};
  }

  &.ant-picker-clear{
    padding: 0.1rem;
    background-color: ${({ theme }) => theme.box};
  }

  &.anticon-close-circle{
    svg{
      font-size: 2rem;
      /* color: ${({ theme }) => theme.main}; */
      color: ${({ theme }) => theme.gray_8};
    }
  }

  .ant-form .ant-form-item .ant-form-item-label {
    flex: none;
  }
  .form_item {
    flex-wrap: nowrap;
  }
`;

export default AntDConfig;
